.blog-index-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-lg);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
}

.blog-index-container h1 {
    color: var(--green);
    font-size: var(--font-size-large);
    text-align: center;
}

.blog-index-container h2 {
    font-size: var(--font-size-medium);
    margin-bottom: 0rem;
    text-align: center;
}

.blog-index-posts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.blog-index-spinner-wrapper {
    display: flex;
  justify-content: center; 
  align-items: center;  
}
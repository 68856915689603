.faq-info-wrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
  background-color: var(--gray);
}

.faq-info-wrapper h1 {
  font-size: var(--font-size-large);
  color: var(--green);
}

.faq-info-wrapper a {
  color: var(--green);
}

.faq-info-wrapper h2 {
  font-size: var(--font-size-x-small);
  font-weight: 700;
  margin-bottom: 0.2rem;
}

.faq-info-wrapper .under-title {
  font-size: 2.4rem;
  font-weight: bold;
}

.accordion-wrapper .under-title {
  font-size: 2rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
  margin-right: 1rem;
  font-weight: bold;
}

.accordion-wrapper {
  padding-top: 3.5rem;
  padding-bottom: 2rem;
  overflow-x: hidden;
  height: fit-content;
  margin: 0 auto;
  background-color: var(--gray);
}

.accordion-wrapper h1 {
  font-size: var(--font-size-large);
  color: var(--green);
  margin-left: 2rem;
  padding-bottom: 2rem;
}

.accordion-wrapper a {
  color: var(--green);
}

.contact-back-button-wrapper {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.faq-list *,
.faq-list *::before,
.faq-list *::after {
  padding: initial;
}

.faq-list {
  padding: 1rem;
}

ol.faq-list {
  margin-left: 2rem;
}

.faq-point {
  padding: 0.3rem 0;
}

.faq-point::marker {
  color: var(--green);
  font-size: 1.2em;
}

.text-bold {
  font-weight: bold;
}

/* Hide .faq-info-wrapper on mobile view and display .accordion-wrapper */
@media (max-width: 767px) {
  .faq-info-wrapper {
    display: none;
  }
  .accordion-wrapper {
    display: block;
    height: fit-content;
    min-height: auto;
  }
  main.accordion-wrapper {
    min-height: auto;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.3rem;
    width: 80%;
  }

  .faq-list {
    padding: 2rem;
    padding-top: 1rem;
  }

  ol.faq-list {
    margin-left: 0rem;
  }
}

/* Hide .accordion-wrapper on desktop view and display .faq-info-wrapper */
@media (min-width: 768px) {
  .faq-info-wrapper {
    display: block;
  }
  .accordion-wrapper {
    display: none;
  }
}

.room-types-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  width: 30rem;
  background-color: var(--white);
  border-radius: 28px;
}

.room-types-modal-checkboxes {
  height: 50rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(min-content, 1fr));
  padding: 4rem 0 4rem 6%;
  overflow: auto;
}

.room-types-modal-checkboxes:last-child {
  margin-bottom: 2.9rem !important;
}

#rooms-types-modal-buttons {
  padding: 0 6% 0.75rem;
  height: 9.65rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e2dfdf;
}

#rooms-types-modal-close-btn {
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 4rem;
  /* Set the width and height to the same value */
  height: 4rem;
  /* Set the width and height to the same value */
  font-size: var(--font-size-xx-small);
  padding: 0;
  background: none;
  color: var(--gray-dark);
  cursor: pointer;
}

.room-types-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
}
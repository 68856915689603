.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  width: min-content;
  padding: var(--spacing-lg);
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;
}

.confirmation-modal h2 {
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 1.75rem;
  white-space: nowrap;
}

.confirmation-modal p {
  font-size: 1.8rem;
  line-height: 25px;
  overflow-wrap: break-word;
  margin-bottom: 1rem;
}

.confirmation-modal span {
  font-size: 1.8rem;
  font-weight: 700;
  overflow-wrap: break-word;
  color: var(--red);
}

.confirmation-modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.confirmation-modal-btn {
  min-width: 17.5rem;
  padding: 1.75rem 5rem;
  margin: 0 0.7rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
  border-radius: 29px;
  border: none;
  background-color: var(--green);
  color: var(--white);

  &-white {
    color: var(--text-black);
    background-color: var(--white);
    box-shadow: var(--shadow-light);
  }
}

.confirmation-modal-btn:hover {
  color: var(--white);
  background-color: var(--green);
}

.confirmation-modal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  height: 8rem;
  width: 8rem;
  background: none;
  border: none;
}

@media only screen and (max-width:548px) {
  .confirmation-modal-btn {
    padding: 1.60rem 2rem;
  }
}

@media only screen and (max-width:980px) {
  .confirmation-modal {
    width: 95%;
    padding: var(--spacing-xxl);
  }

  .confirmation-modal h2 {
    font-size: 3rem;
    white-space: normal;
  }

  .confirmation-modal-btn-row {
    display: block;
  }
}


.confirmation-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  background-color: var(--gray-black);
  opacity: 0.5;
}
.register-form {
  min-height: 100vh;
  padding: var(--spacing-lg) 0;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--gray-light);
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
}

.register-form-container {
  width: 77rem;
  border-radius: 52px;
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
}

.register-form-container h1 {
  font-size: 3.6rem;
  font-family: var(--montserrat);
  font-weight: 700;
  margin-bottom: 5.5rem;
  text-align: center;
}

.register-form-container form {
  margin: 0 4.5rem;
}

.register-form-container form label {
  font-size: var(--font-size-x-small);
  margin-left: 0.5rem;
}

.register-form-container form .input-field,
.register-form-container form .register-password-div input {
  display: block;
  width: 100%;
  height: 5rem;
  font-size: var(--font-size-xx-small);
  padding: 0px 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgb(198, 203, 207);
  border-radius: 50px;
}

.register-form-checkbox {
  display: grid;
  grid-template-columns: 2rem auto;
  grid-template-rows: 3rem;
  gap: 10px;

}

.register-form-checkbox input {
  cursor: pointer;
  grid-column: 1;
  accent-color: var(--green);
}

.register-form-checkbox div {
  grid-column: 2;
}

.register-form-checkbox a {
  color: var(--green);
}

.register-form-button {
  text-align: center;
}

.register-form-container form .register-form-error-msg {
  font-size: var(--font-size-xx-small);

  color: var(--red);
  margin-left: 0.5rem;
}

.register-form-container .register-password-div {
  position: relative;
}

.register-form-container .register-password-div .show-password {
  width: 30px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
}

.register-form-container button {
  width: 18rem;
  height: 5.8rem;
  margin-bottom: 2rem;
  border-radius: 29px;
  font-size: var(--font-size-x-small);
  font-weight: 700;
  border: none;
  color: var(--white);
  background-color: var(--green);
  box-shadow: var(--shadow-light);
  transition: all 0.15s ease-in-out;
}

.register-form-container button:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-medium);
}

.register-form-container p {
  font-size: var(--font-size-xx-small);
  padding-top: 5px;
  margin-bottom: 4rem;
}

.register-form-container .input-container {
  width: 100%;
}

.register-form-error-msg-server {
  display: grid;
  align-items: center;
  font-size: var(--font-size-x-small);
  text-align: center;
  color: var(--red);
}

.register-form-terms-text {
  font-size: var(--font-size-xx-small) !important;
}

@media only screen and (max-width: 900px) {
  .register-form-container {
    width: 90%;
    margin: 0px auto;
    border-radius: 40px;
    padding: var(--spacing-md) var(--spacing-xs);
  }

  .register-form-container h1 {
    font-size: 3.2rem;
  }
}


@media only screen and (max-width: 550px) {
  .register-form-container form {
    margin: 0 1.5rem;
  }

  .register-form-container button {
    margin-top: 2rem;
  }

  .register-form-checkbox {
    margin-left: -8px;
  }
}
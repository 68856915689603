.thank-you-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 8rem 10rem;
  background-color: var(--white);
  border-radius: 28px;

  max-width: 1600px;
  margin: 0 auto;
}

.thank-you-component h2 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  white-space: nowrap;
}

.thank-you-component p {
  font-size: 2rem;
  overflow-wrap: break-word;
  margin-bottom: 1rem;
  text-align: center;
}

.thank-you-component span {
  color: var(--green);
}

.thank-you-modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.thank-you-modal-btn {
  min-width: 17.5rem;
  padding: 1.75rem 5rem;
  margin: 0 0.7rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
  border-radius: 29px;
  border: none;
  background-color: var(--green);
  color: var(--white);
  margin-bottom: 2rem;
  cursor: pointer;

  &-white {
    color: var(--text-black);
    background-color: var(--white);
    box-shadow: var(--shadow-light);
  }
}

.thank-you-modal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  height: 8rem;
  width: 8rem;
  background: none;
  border: none;
}

.thank-you-request {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you-request button {
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.thank-you-request p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .thank-you-component {
    width: 95%;
    margin: 15rem 1rem 5rem;
    padding: 6rem 2rem 4rem;
  }

  .thank-you-component h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    white-space: normal;
    text-align: center;
  }

  .thank-you-component p {
    font-size: 2rem;
  }
}
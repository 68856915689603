.sidebar-component {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-light);
  padding-bottom: var(--spacing-lg);
}

.sidebar-component nav {
  position: fixed;
  top: 25rem;
  display: flex;
  flex-direction: column;

  @supports (position: sticky) {
    position: sticky;
  }
}



.dashboard-sidebar-tab {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-x-small);

  color: var(--text-black);
  text-decoration: none;
  white-space: nowrap;
  padding: 0.2rem 1rem;
  margin-bottom: 2rem;

  transition: color 0.15s ease-in;
}

.dashboard-sidebar-tab div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 100px;
  background-color: transparent;
  font-size: 2.4rem;
}

.dashboard-sidebar-tab p {
  display: inline-block;
  margin: auto 0;
  padding-left: 2.2rem;
}

.dashboard-sidebar-tab:hover {
  color: var(--green);
}

.dashboard-sidebar-tab-active div {
  background-color: var(--green);
}

.dashboard-sidebar-tab-active svg {
  color: var(--white);
}

.dashboard-sidebar-tab-active p {
  color: var(--green);
}



@media screen and (max-width: 1200px) {
  .dashboard-sidebar-tab p {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .sidebar-component {
    margin-bottom: 0rem;
    padding-bottom: 0rem !important;
    background-color: var(--white);
  }

  .sidebar-component nav {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    margin-top: 1rem;
  }

  .dashboard-sidebar-tab {
    margin: 0;
  }
}
.confirmation-modal-component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  width: 50rem;
  padding: 5rem 0;
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
}

.confirmation-modal-component h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  font-family: var(--montserrat);
  margin-bottom: 1.2rem;
}

.confirmation-modal-component p {
  font-size: var(--font-size-xx-small);
  line-height: 25px;
  padding-bottom: 1rem;
}

.confirmation-modal-component b {
  font-size: var(--font-size-xx-small);
  line-height: 25px;
  padding-bottom: 4rem;
}

.confirmation-button-link {
  padding: 1.75rem 5rem;
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  color: var(--white);
  text-decoration: none;
  background-color: var(--green);
  border-radius: 29px;
}

.confirmation-modal-component button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  height: 8rem;
  width: 8rem;
  background: none;
  border: none;
}

@media only screen and (max-width: 750px) {
  .confirmation-modal-component {
    width: 500px;
  }
}

@media only screen and (max-width: 550px) {
  .confirmation-modal-component {
    width: 450px;
    padding: 5rem 1rem 1rem 1rem;
  }
}

@media only screen and (max-width: 460px) {
  .confirmation-modal-component {
    width: 390px;
  }
}

@media only screen and (max-width: 400px) {
  .confirmation-modal-component {
    width: 360px;
    padding: 5rem 0.5rem 0.5rem 0.5rem;
  }
}


.confirmation-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  background-color: var(--gray-black);
  opacity: 0.5;
}
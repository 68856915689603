.intro-section {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  padding: var(--spacing-lg) var(--spacing-xl);
  gap: 3rem;
}

.intro-section h1 {
  font-weight: 700;
  font-size: 5rem;
  line-height: 1.2;
  color: var(--text-black);
  white-space: nowrap;
  z-index: var(--z-index-above);
}

.intro-section img {
  max-width: 43rem;
  min-width: 210px;
  width: 100%;
  height: auto;
  /* transform: translateY(-12.5%); */
}

#home-intro-title {
  text-align: center;
}

.intro-section p {
  color: var(--text-black);
  font-size: 2.4rem;
  font-weight: 700;
  margin: 3rem 0;

  &:first-child {
    margin-top: 0;
  }
}

.home-intro-bottom-text {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;

  a {
    color: var(--green);
  }
}

.button-spacing {
  display: flex;
  gap: 1rem;
}

.button-spacing-scaling {
  display: flex;
  gap: 1rem;
  margin-top: 6rem;
}
@media only screen and (max-width: 1450px) {
  .intro-section h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 1300px) {
  .intro-section h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .intro-section {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: var(--spacing-lg);
  }

  #home-intro-title {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(10px, max-content)) 1fr;
    margin: 0 0 4rem;
  }

  .intro-section h1 {
    transform: translateX(10%);
    grid-column: 2 / 3;
  }

  .intro-section img {
    transform: translateY(10%);
    grid-column: 3 / 4;
  }
}

@media only screen and (min-width: 1200px) {
  .intro-section {
    gap: 5rem;
  }
}

@media only screen and (max-width: 1250px) {
  .intro-section h1 {
    font-size: 3.5rem;
  }

  .intro-section {
    gap: 1rem;
  }
}

/* @media only screen and (max-width: 1120px) {
  .intro-section {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: var(--spacing-lg);
  }

  #home-intro-title {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(10px, max-content)) 1fr;
    margin: 0 0 4rem;
  }

  .intro-section h1 {
    transform: translateX(10%);
    grid-column: 2 / 3;
  }

  .intro-section img {
    transform: translateY(10%);
    grid-column: 3 / 4;
  }
} */

@media only screen and (max-width: 950px) {
  .intro-section {
    align-items: flex-start;
    padding-right: 0;
    gap: 0rem;
  }

  #home-intro-title {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, min-content);
    margin-bottom: 0;
  }

  .intro-section h1 {
    grid-column: 1 / -1;

    line-height: 1;
    transform: translate(0, 0);
  }

  .intro-section img {
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    transform: translate(0, -15%);
  }
}

@media only screen and (max-width: 765px) {
  .button-spacing {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
}

@media only screen and (max-width: 600px) {
  .intro-section h1 {
    white-space: normal;
    width: auto;
  }

  .button-spacing {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .button-spacing-scaling {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 460px) {
  .intro-section h1 {
    font-size: 3rem;
    margin-right: 2rem;
  }

  .intro-section img {
    grid-column: 1 / -1;
    justify-self: center;
    padding: 0 var(--spacing-lg);
    transform: translate(0, -5%);
  }
}

@media only screen and (max-width: 380px) {
  .intro-section {
    padding-left: var(--spacing-sm);
  }
}

.latest-news-title {
  font-size: var(--font-size-small);
  font-weight: 800;
  color: var(--green);
  align-self: center;
  text-align: center;
  margin-bottom: 4rem;
}
.latest-news {
  font-size: var(--font-size-xx-small);
  text-align: center;
  margin-bottom: 8rem;
  padding: 2rem;
}

.latest-news :nth-child(4) {
  padding-bottom: 4rem;
}

@media (min-width: 1000px) {
  .latest-news {
    margin-right: 8rem;
    margin-left: 8rem;
  }
}

.space-dropdown {
  margin-top: 1rem;
  gap: 2rem;
  position: absolute;
  left: 2rem;
  z-index: var(--z-index-modal);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
  padding: 20px;
  width: 90%;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.space-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space-dropdown-top-text {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: 700;
}

.space-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.space-checkbox-inner-wrapper {
  margin-bottom: 10px;
}

.show-more-wrapper {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: auto;
  align-items: center;
}

.space-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space-dropdown-show-more {
  align-self: flex-end;
  font-size: var(--font-size-xx-small);
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 530px) {
  .space-dropdown {
    width: 380px;
  }
}

@media only screen and (max-width: 530px) {
  .space-dropdown {
    width: 320px;
  }
}

@media only screen and (max-width: 370px) {
  .space-dropdown {
    width: 320px;
  }
}

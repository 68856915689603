.blog-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
}

.blog-card-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-card-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.blog-card-thumbnail {
  height: 38rem;
  width: 100%;
  object-fit: cover;
  border-radius: 26px;
}

.blog-card-title {
  font-size: var(--font-size-xx-small);
  font-weight: bold;
  color: var(--text-black);
  margin-bottom: 10px;
  transition: color 0.2s ease;
  margin-top: 2rem;
}

.blog-card-item:hover .blog-card-title {
  color: var(--green);
}

.blog-card-item b {
  font-size: 15px;
  color: #999;
  margin-top: auto;
}

.blog-card-container p {
  font-size: var(--font-size-x-small);
  /* text-align: center; */
  grid-column: span 2;
}
#ad-page-booking {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4.5rem 10% 5.5rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: var(--shadow-light);
}

#ad-page-booking h1 {
  font-size: var(--font-size-small);
  font-weight: 700;
  margin: 0;
  white-space: normal;
  word-wrap: break-word;
}

#ad-page-booking h2 {
  font-size: var(--font-size-x-small);
  margin: 0;
}

#ad-page-booking-price {
  display: grid;
  grid-template-columns: auto min-content;
  font-size: var(--font-size-xx-small);
  padding: 2.25rem 0;
  margin-bottom: auto;
}

#ad-page-booking-price {
  display: flex;
  justify-content: space-between;
}

#ad-page-booking-price p {
  margin-bottom: 0.75rem;
}

#ad-page-booking-price span {
  font-weight: 700;
  color: var(--text-black);
}

#ad-page-booking-summary {
  font-size: 1.5rem;
  line-height: 18px;
  margin-bottom: 1rem;
  text-align: center;
}

#ad-page-booking-button {
  display: block;
  margin: 2rem auto 0;
  border-radius: 100px;
  border: none;
  width: 25.5rem;
  height: 7.2rem;
  background-color: var(--green);
  font-size: var(--font-size-x-small);
  font-weight: 700;
  color: var(--white);
  transition: filter 125ms ease-in;
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
  #ad-page-booking {
    padding: 4rem 4% 5rem;
  }

  #ad-page-booking h1 {
    margin-bottom: 0.5rem;
    white-space: normal;
  }

  #ad-page-booking-info button {
    margin-bottom: 0.65rem;
  }

  #ad-page-booking-summary {
    line-height: 20px;
    max-width: 72.5rem;
  }

  #ad-page-booking-button {
    min-width: 200px;
    width: 50%;
  }

  #ad-page-booking-button:hover {
    background-color: #292E45;
  }
}

@media only screen and (max-width: 600px) {
  #ad-page-booking {
    padding: 3rem 6% 3.5rem;
  }

  #ad-page-booking h1 {
    white-space: normal;
    word-wrap: break-word;
  }

  #ad-page-booking h2 {
    white-space: normal;
  }

  #ad-page-booking-price {
    grid-template-columns: 1fr;
  }
}
 .verify-user-modal-component {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: var(--z-index-modal);
   max-width: 72.5rem;
   padding: var(--spacing-lg) var(--spacing-xl) var(--spacing-md);
   background-color: var(--white);
   border-radius: 28px;
 }

 .verify-user-modal-component h1 {
   font-size: 3.5rem;
   font-weight: 700;
   margin-bottom: 2rem;
   text-align: center;
 }

 .verify-user-modal-component p {
   font-size: 1.7rem;
   margin-bottom: 3.2rem;
 }

 .verify-user-modal-component a {
   color: var(--green);
 }


 #verify-modal-success {
   font-size: 2rem;
   text-align: center;
   color: var(--green);
 }

 #verify-modal-error {
   font-size: 2rem;
   text-align: center;
   color: var(--red);
 }

 .verify-form-password-div {
   position: relative;
 }

 .verify-form-show-password {
   position: absolute;
   top: 50%;
   right: 10%;
 }

 .verify-form-show-password-div {
   position: relative;
 }

 .login-form-show-password {
   position: absolute;
   width: 30px;
   top: 50%;
   right: 10px;
   /* Change the value as per your requirement */
   transform: translateY(-50%);
   cursor: pointer;
 }

 .verify-user-modal-component form {
   label {
     font-size: 2rem;
     margin-bottom: 0.5rem;
     margin-left: 0.5rem;
   }
 }

 .verify-user-modal-component input {
   display: block;
   width: 100%;
   height: 5rem;
   font-size: 1.6rem;
   padding: 0 2.5rem;
   border: 1px solid #c6cbcf;
   border-radius: 50px;

   &:last-child {
     margin-bottom: 0;
   }
 }

 #verify-modal-forgot-password {
   width: 100%;
   margin: 1rem 0 4rem;
   text-align: right;

   a {
     font-size: 1.5rem;
     color: var(--text-black);
     margin-right: 1rem;
   }
 }


 #verify-modal-form-button {
   margin-bottom: 4rem;

   button {
     display: block;
     margin: 0 auto;
     width: 19rem;
     height: 5rem;
     font-size: 2.2rem;
     font-weight: 700;
     color: var(--white);
     border-radius: 50px;
     background-color: var(--green);
     box-shadow: var(--shadow-light);
     border: none;
   }
 }

 #verify-modal-close-button {
   position: absolute;
   top: 0.5rem;
   right: 0.5rem;

   height: 8rem;
   width: 8rem;
   background: none;
   border: none;
 }



 .verify-user-modal-backdrop {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   z-index: var(--z-index-backdrop);
   background-color: var(--gray-black);
   opacity: 0.5;
 }
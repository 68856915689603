.forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-light);
  border-bottom: 1px solid var(--gray-medium);
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
}

.forgot-password h1 {
  font-size: var(--font-size-small);
  font-family: var(--montserrat);
  font-weight: 700;
  margin-bottom: 5.5rem;
  text-align: center;
}

.forgot-password form label {
  font-size: var(--font-size-xx-small);
}

.forgot-password input {
  display: block;
  width: 100%;
  height: 5rem;
  font-size: 1.6rem;
  padding: 0 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 1px solid #c6cbcf;
  border-radius: 50px;
}

.forgot-password form span {
  font-size: var(--font-size-xx-small);
  color: red;
  padding-top: 1rem;
  margin-bottom: 2.5rem;
}

.forgot-password form div {
  display: block;
  text-align: center;
}

#forgot-password-confirm {
  text-align: center;
  white-space: nowrap;
  color: var(--green);
  margin-bottom: 2rem;
}

.forgot-password form div button {
  width: 18rem;
  height: 5rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  border-radius: 29px;
  font-size: 1.8rem;
  font-weight: 700;
  border: none;
  color: var(--white);
  background-color: var(--green);
  box-shadow: var(--shadow-light);
}

.forgot-password p {
  font-size: var(--font-size-xx-small);
  padding-top: 1rem;
  margin-bottom: 2.5rem;
}


#forgot-password-server-error-msg {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--red);
  margin-bottom: 2rem;
}

.forgot-password-input-container {
  display: grid;
}

.forgot-password-error-msg {
  font-size: 1.7rem;

  color: var(--red);
  margin-left: 0rem;
}

@media only screen and (max-width: 768px) {
  .forgot-password {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
  }
}
.search-result-card {
  width: 100%;
  min-width: 250px;
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: var(--shadow-light);
  height: 40rem;
  background-color: #fafafa;
  transition: all 250ms ease-in-out;
  color: black;
}

article {
  max-width: 39rem;
  width: 100%;
}

.ad-card-outer-link {
  text-decoration: none;
  color: black;
}

.search-result-card:hover.true {
  transform: scale(1.05);
  transition: all 150ms ease-in-out;
}

.search-result-card:hover.false {
  transform: scale(0);
  transition: none;
}

.search-ad-card {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.6rem;
  width: 100%;
  height: auto;
}

.search-ad-card-heading {
  margin-top: -1rem;
}

.search-ad-card-heading h3 {
  font-size: 1.6rem;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  white-space: normal;
}

.search-ad-card p {
  font-size: 1.6rem;
  flex-grow: 1;
  margin-bottom: 10px;
  font-weight: 500;
}

.search-ad-card h4 {
  flex-grow: 1;
  font-size: 1.6rem;
  font-weight: 600;
  color: #222;
  width: 100%;
  line-height: normal;
  height: 4rem;
}

.search-ad-card-info {
  margin-top: -1rem;
  font-size: 1.5rem;
  height: 2rem;
  margin-bottom: 2rem;
}

.adcard-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  aspect-ratio: 16 / 9;
  background-color: var(--blue-light);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;
}

.adcard-no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  background-color: var(--blue-light);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;
  margin-bottom: 8px;
}

.slash-eye-icon {
  width: 8rem;
  align-self: center;
  fill: var(--text-black);
}

.search-ad-card-price {
  min-height: 5rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.search-ad-card-price span {
  display: flex;
  font-weight: 600;
  font-size: 1.4rem;
}

.search-ad-card-price p {
  font-weight: 600;
  font-size: 1.5rem;
}

@media only screen and (max-width: 380px) {
  .search-result-card {
    width: 95%;
  }
}

.breadcrumbs-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  width: 100%;
  cursor: default;
}

.breadcrumbs-inner {
  font-size: var(--font-size-xx-small);
  margin-left: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin: 1rem;
  padding: 0rem 0rem 0rem;
}

.breadcrumbs-inner a {
  text-decoration: none;
  color: var(--text-black);
}

.breadcrumbs-inner a:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 630px) {
  .breadcrumbs-inner {
    font-size: var(--font-size-xx-small);
    margin-left: 1.6rem;
    display: flex;
    gap: 0.4rem;
    margin: 0rem 0rem 1rem 0rem;
    padding: 0rem 0rem 0rem;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px;

  }
}

@media only screen and (max-width: 440px) {
  .breadcrumbs-inner u {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
  }
}
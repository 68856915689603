.forrenter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.forrenter-intro-content {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 4rem;
}

.forrenter-intro-text h1 {
  color: var(--green);
  font-size: var(--font-size-large);
  word-break: break-all;
}

.forrenter-intro-text h2 {
  font-size: var(--font-size-medium);
  margin-bottom: 0;
  text-align: left;
}
.forrenter-intro-text p,
.forrenter-intro-text li {
  font-size: var(--font-size-xx-small);
  margin: 0;
}

.forrenter-intro-text ul,
ol {
  margin-left: 2rem;
}

.forrenter-intro-text ul li {
  margin-bottom: 1rem;
}

.forrenter-intro-text ul li::marker {
  color: var(--green);
}

.forrenter-intro-text ol li::marker {
  color: var(--green);
}

.forrenter-intro-text ol li {
  margin-bottom: 1rem;
}

.forrenter-intro-text h3 {
  margin-top: 2rem;
  font-size: 20px;
}

.forrenter-intro-text a {
  color: var(--green);
}

@media only screen and (max-width: 980px) {
  .forrenter-intro-content {
    padding: 0;
  }
}

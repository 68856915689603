.mobile-nav {
  display: none;
}

@media only screen and (max-width: 980px) {
  .mobile-nav {
    display: flex;
    bottom: 0;
    right: 0;
    color: var(--gray-black);
    z-index: var(--z-index-navbar);
    background-color: var(--green-light);
    border-radius: 50px;
  }

  .mobile-nav .items {
    display: flex;
    pointer-events: none;
    opacity: 0;
    transform: translateX(6rem);
    flex-direction: column;
    gap: 1rem;
    background: var(--gray-light);
    padding: 2rem 3rem 2rem 1.5rem;
    border-radius: 2rem 0 0 2rem;
    box-shadow: 1rem 1rem 2rem #0005;
    position: fixed;
    right: 0;
    top: 7rem;
    transition: all 0.2s;
  }

  .mobile-nav .items a,
  .mobile-nav .items button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 2.2rem;
    text-decoration: none;
    color: var(--text-black);
    background: none;
    border: none;
    cursor: pointer;
  }

  .mobile-nav .items a svg {
    margin-right: 1rem;
    width: 1.25rem;
  }

  .mobile-nav .toggle {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 99px;
    display: grid;
    place-items: center;
    transition: all 0.2s;
    cursor: pointer;
  }

  .mobile-nav .toggle span {
    display: block;
    position: relative;
    height: 2px;
    width: 4rem;
    background: var(--gray-black);
    border-radius: 3px;
    transition: all 0.2s;
  }

  .mobile-nav .toggle span::before,
  .mobile-nav .toggle span::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 4rem;
    background: var(--gray-black);
    border-radius: 3px;
    transition: all 0.2s;
  }

  .mobile-nav .toggle span::before {
    transform: translateY(-6px);
  }

  .mobile-nav .toggle span::after {
    transform: translateY(6px);
  }

  .mobile-nav.open .items {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0);
  }

  .mobile-nav.open .toggle span {
    background: none;
  }

  .mobile-nav.open .toggle span::before {
    background: var(--gray-black);
    transform: translate(0) rotate(45deg);
  }

  .mobile-nav.open .toggle span::after {
    background: var(--gray-black);
    transform: translate(0) rotate(-45deg);
  }
}
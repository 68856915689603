.popular-ads-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.popular-ads-inner {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 4rem;
}

.outer-popular-ads-copy {
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popular-ads-copy-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}

.popular-ads-copy-wrapper a {
  color: var(--green);
}

.popular-ads-copy-intro h1 {
  color: var(--green);
  font-size: var(--font-size-large);
}

.popular-ads-copy-intro h2 {
  font-size: var(--font-size-medium);
  text-align: left;
  margin-bottom: 1.2rem;
  white-space: wrap;
  margin-bottom: 1.2rem;
}

.popular-ads-copy-intro h3 {
  font-size: var(--font-size-small);
  text-align: left;
  margin-bottom: 1.2rem;
  white-space: wrap;
  margin-bottom: 1.2rem;
}

.popular-ads-copy-intro p {
  font-size: var(--font-size-xx-small);
}

.popular-ads-copy-list-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: var(--font-size-xx-small);
}

.popular-ads-copy-list-wrapper ul {
  margin-left: 4rem;
}

.popular-ads-copy-wrapper a.rounded-link-button {
  color: var(--white);
}

.popular-ads-copy-list-wrapper h3 {
  font-size: var(--font-size-small);
  text-align: left;
  margin-bottom: 1.2rem;
  white-space: wrap;
  margin-bottom: 1.2rem;
}

.popular-ads-copy-outro {
  p {
    margin-bottom: 1rem;
    font-size: var(--font-size-xx-small);
  }
}

@media only screen and (max-width: 980px) {
  .popular-ads-copy-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 720px) {
  .popular-ads-copy-wrapper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 980px) {
  .popular-ads-copy-intro h1 {
    font-size: var(--font-size-mobile-large);
  }

  .popular-ads-copy-intro h2 {
    font-size: var(--font-size-mobile-small);
  }

  .outer-popular-ads-copy {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 460px) {
  .popular-ads-inner {
    padding: var(--spacing-sm);
    margin: 0;
  }

  .popular-ads-copy-list-wrapper ul {
    margin-left: 2rem;
  }

  .popular-ads-copy-list-wrapper ul li {
    margin-bottom: 1rem;
  }

  .popular-ads-copy-intro h1 {
    margin-top: 3.5rem;
  }
}

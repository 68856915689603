.team-container {


  padding-top: 4rem;
  padding-bottom: 2rem;
}

.about-us-team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55rem;
}

.about-us-team-member img {
  height: 250px;
  width: 250px;
  image-resolution: from-image;
  border-radius: 50%;
  overflow: hidden;
  transition: 150ms ease-in-out;
}

.about-us-team-member img:hover {
  transform: scale(1.05);
  transition: 150ms ease-in-out;
}

.about-us-team-member h2 {
  text-align: center;
  font-size: var(--font-size-medium);
}

.about-us-team-member h3 {
  font-size: var(--font-size-x-small);
  margin-top: 3rem;
}

.about-us-team-member h4 {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-us-team-member p {
  font-size: var(--font-size-xx-small);
  width: 380px;
  text-align: center;
}

@media only screen and (max-width: 1530px) {
  .about-us-team {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    align-self: center;
  }
}

@media only screen and (max-width: 980px) {
  .team-container {
    display: flex;
    width: 100%;
  }

  .about-us-team-member {
    width: 100%;
  }

  .team-container h2 {
    margin: 0;
    padding: 0;
  }

  .about-us-team-member img {
    width: 250px;
    height: 250px;
  }

  .about-us-team-member p {
    width: 100%;
    margin-left: 0rem;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-team-member img {
    width: 200px;
    height: 200px;
  }
}
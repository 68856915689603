.loading-component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  text-align: center;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
}

.loading-component p {
  margin-top: 4rem;
  font-size: 2.25rem;
  color: var(--white);
}

.loading-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  background-color: var(--gray-black);
  opacity: 0.5;
}
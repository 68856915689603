.scaling-button {
  font-weight: 600;
  background-color: var(--white);
  border: 2px var(--green) solid;
  color: var(--green);
  border-radius: 50px;
  font-size: 14px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.scaling-button:hover {
  box-shadow: var(--shadow-medium);
  transform: scale(1.05);
}

.scaling-button.sm {
  height: 4rem;
  width: 14rem;
  font-size: var(--font-size-xx-small);
}

.scaling-button.md {
  height: 5.8rem;
  width: 15rem;
  font-size: var(--font-size-xx-small);
}

.scaling-button.lg {
  height: 6.5rem;
  width: 24rem;
  font-size: var(--font-size-x-small);
  font-weight: 500;
}

.scaling-button.white {
  background-color: var(--green);
  color: var(--white);
}
  .description p:nth-child(1) {
    font-size: var(--font-size-xx-small);
    white-space: pre-line;
    font-weight: 700;
  }

  .description p {
    font-size: var(--font-size-xx-small);
    white-space: pre-line;
  }

  @media only screen and (max-width: 1320px) {
    .description p {
      width: calc(100%);
      display: inline-block;
      word-wrap: break-word;
      font-size: var(--font-size-xx-small);
    }
  }
.unit-type-section {
  width: 90%;
  margin: auto;
  padding: 50px 0px 70px 0;
}

.unit-outer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px 20px;
  grid-template-rows: 40px auto;
  justify-items: center;
  justify-content: center;
  align-items: center;
  max-width: 200rem;
  margin: 0 auto;
}

@media only screen and (max-width: 960px) {
  .unit-outer {
    padding: var(--spacing-xxs) 5% 0;
    grid-template-columns: 1fr;
    grid-template-rows: 40px repeat(4, 1fr);
    grid-gap: 0;
  }
}

.unit-outer h3 {
  font-weight: 600;
  font-size: 2.4rem;
  grid-column: 1 / span 4;
  place-self: end start;
}
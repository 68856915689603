.most-recent-outer {
  background-color: var(--green);
  padding: 20px;
  border-radius: 8px;
  color: white;
  height: fit-content;
  width: 30rem;
  margin-top: 10rem;
}

.most-recent-container {
  max-width: 400px;
  margin: 0 auto;
}

.most-recent-container h3 {
  font-size: var(--font-size-x-small);
  margin-bottom: 2rem;
}

.most-recent-item {
  display: block;
  margin-bottom: 2rem;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
  position: relative;
}

.most-recent-item:hover .most-recent-title {
  text-decoration: none;
}

.most-recent-title {
  font-size: var(--font-size-xx-small);
  font-weight: 600;
  margin: 0;
  color: var(--white);
  position: relative;
  z-index: 1;
  /* Keeps the text above the hr */
}

.most-recent-title:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--white);
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: width 0.3s ease;
}

.most-recent-date {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--orange);
}

.most-recent-item:hover hr {
  display: block;
}

.most-recent-container hr {
  border: none;
  border-top: 2px solid var(--white);
  margin: 0;
  display: none;
}

.most-recent-spinner-wrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .most-recent-outer {
    margin-top: 2rem;
    justify-self: center;
  }
}

.checkbox-wrapper {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.checkbox-input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.checkbox-label {
  margin-left: 1rem;
  font-size: var(--font-size-xx-small);

}

.multi-line-label {
  display: block;
}
.ad-section-container {
  display: grid;
  gap: 1rem;
  margin: 0rem 0rem 0rem 1rem;
}

.ad-section-top-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.ad-section-head-text {
  display: flex;
  flex-direction: column;
  max-width: 53rem;
}

.ad-section-head-text h1 {
  font-size: var(--font-size-large);
  color: var(--green);
}

.ad-section-head-text p {
  font-size: var(--font-size-xx-small);
  font-weight: 400;
}

.ad-section-head-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: var(--font-size-xx-small);
  gap: 4px;
}

.ad-section-head-inner h4 {
  font-weight: bold;
}

.ad-section-head-link {
  color: var(--green);
}

.ad-section-search {
  width: auto;
  display: flex;
  justify-content: space-between;
}

.ad-section-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 2rem;
}

/* .ad-section-filters-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 0.5rem;
} */

.results-counter {
  font-size: var(--font-size-x-small);
  letter-spacing: -1px;
}

.ad-section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.ad-page-button-wrapper {
  align-self: center;
}

.ad-page-button-wrapper button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.single-column {
  display: grid;
  margin-right: 2rem;
  margin-top: 2rem;
}

/* Two Column Layout */
.two-column {
  display: grid;
  grid-template-columns: auto 43%;
  gap: 1rem;
}

.map-placeholder {
  grid-template-columns: 2;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto;
}

.map-wrap-time {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0;
}

.ad-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1rem 1%;
  margin-bottom: 4rem;
  list-style-type: none;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  grid-column: 1 / -1;
  /* Removes grid columns */
  grid-row: 1 / -1;
  /* Removes grid rows */
  min-height: 300px;
}

.no-result-wrapper {
  display: flex;
  justify-content: center;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.ad-setion-toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-switch {
  display: inline-block;
  margin-top: 5px;
  background: #ccc;
  border-radius: 16px;
  width: 40px;
  height: 15px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  cursor: pointer;
}

.toggle-switch:before {
  content: "";
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 4px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
  background: var(--green);
  /* Green color for "checked" state */
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 27px;
}

.toggle-label {
  font-size: var(--font-size-xx-small);
  font-weight: 600;
}

.active {
  color: var(--green);
}

.ad-page-filter-select {
  width: 18rem;
  height: 3.5rem;
  font-size: var(--font-size-xx-small);
  border: 1px solid #c6cbcf;
  border-radius: 100px;
  background: var(--white);
  padding: 0 1.1rem;
}

.ad-page-results {
  font-size: var(--font-size-x-small);
  font-weight: 600;
}

.toggle-small {
  display: none;
}

@media only screen and (min-width: 1620px) {
  .ad-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

@media only screen and (max-width: 1390px) {
  .ad-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 950px) {
  .ad-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media only screen and (max-width: 980px) {
  .ad-section-head-text h1 {
    font-size: var(--font-size-mobile-large);
    color: var(--green);
  }
}

@media only screen and (max-width: 800px) {
  .toggle {
    display: none;
  }

  .toggle-small {
    display: inline;
  }

  .map-wrap-time {
    height: 40vh;
  }

  .map-placeholder {
    width: 100%;
  }

  .ad-section-head-inner {
    display: grid;
    width: initial;
    /* grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr; */
  }

  .ad-section-head-inner label {
    grid-column: 2;
    grid-row: 1 / span 2;
    justify-self: end;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .ad-section-filters {
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .ad-section-search {
    margin-top: 1rem;
    align-self: center;
  }
}

@media only screen and (max-width: 548px) {
  .ad-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 1rem;
  }

  .ad-section-container {
    display: grid;
    gap: 2rem;
    margin: 1rem 0rem 0rem 2rem;
  }

  .ad-page-results {
    font-size: var(--font-size-xx-small);
  }
}

@media only screen and (max-width: 392px) {
  .ad-section-container {
    margin: 0rem 0rem 0rem 2rem;
  }
  .ad-section-head-text {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .ad-section-container {
    margin: 0rem 0rem 0rem 0rem;
  }

  .ad-page-results {
    margin-left: 2rem;
  }

  .ad-cards-container {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 380px) {
  .single-column {
    margin-right: 0;
  }

  .ad-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 2rem;
  }

  .ad-section-top {
    width: 95%;
  }

  .ad-section-head-text {
    width: 34rem;
  }

  .ad-section-search {
    margin-left: 0rem;
  }

  .map-placeholder {
    width: 95%;
  }
}

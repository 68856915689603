.interest-modal.open {
  width: 34rem;
  height: 14rem;
  color: var(--black);
  right: 3rem;
  position: fixed;
  bottom: 20px;
  background-color: var(--white);
  border-radius: 18px;
  border: none;
  box-shadow: var(--shadow-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.interest-modal {
  width: 34rem;
  height: 14rem;
  color: var(--black);
  right: 3rem;
  position: fixed;
  bottom: 20px;
  background-color: var(--white);
  border-radius: 18px;
  border: none;
  box-shadow: var(--shadow-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(120%);
  transition: transform 0.5s ease;
}

.card-holder-inner {
  text-align: center;
}

.card-holder-inner .rounded-button {
  font-weight: 600;
}

.card-close-icon {
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
}

.card-text {
  font-size: var(--font-size-xx-small);
  font-weight: 600;
  margin-bottom: 1rem;
}

@media screen and (min-width: 540px) and (max-width: 780px) {
  .interest-modal.open {
    width: 32rem;
    height: 12rem;
  }

  .interest-modal {
    width: 32rem;
    height: 12rem;
  }

  .card-close-icon {
    top: 5px;
    right: 5px;
  }

  .card-text {
    font-size: 15px;
  }

  .card-holder-inner .rounded-button {
    font-size: 16px;
  }
}

@media screen and (min-width: 285px) and (max-width: 540px) {
  .interest-modal.open {
    width: 31rem;
    height: 11rem;
  }

  .interest-modal {
    width: 31rem;
    height: 11rem;
  }

  .card-close-icon {
    top: 4px;
    right: 8px;
  }

  .interest-modal.open {
    right: 2rem;
  }

  .card-text {
    font-size: 13px;
  }

  .card-holder-inner .rounded-button.lg {
    width: 15em;
    font-size: 15px;
  }
}
.card-link-wrapper {
  text-decoration: none;
}

.interest-card {
  width: 100%;
  min-width: 250px;

  font-size: 2rem;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: var(--shadow-light);
  height: 40rem;
  background-color: #FAFAFA;
  transition: all 250ms ease-in-out;
  color: black;
}

.interest-card-image {
  width: 100%;
  height: 20rem;
  aspect-ratio: 16 / 9;
  background-color: var(--green);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;
  display: grid;
  align-content: center;
  justify-content: center;

}

.interest-card-image-text {
  padding: 1rem;
}

.interest-card-image-text p {

  font-weight: 600;
  color: #FAFAFA;
  font-size: var(--font-size-x-small);
  text-align: center !important;
}

.interest-card-image-text a {

  font-weight: 600;
  color: #FAFAFA;
  text-align: center;
}

.interest-inner-text-card {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  width: 100%;
  height: 20rem;
}

.interest-inner-text-card p {
  font-size: var(--font-size-xx-small);
  flex-grow: 1;
  margin: 0;
  text-align: center;

}

.interest-responsive-button {
  color: var(--white);
  background-color: var(--green);
  border: medium none;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: var(--font-size-xx-small);
  width: 22rem;
  height: 5rem;
}

@media only screen and (max-width: 380px) {
  .interest-card {
    width: 95%;
  }
}
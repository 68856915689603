.forgot-password-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-light);
  border-bottom: 1px solid var(--gray-medium);
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
}
.forgot-password-component {
  width: 60rem;
  border-radius: 52px;
  padding: 8rem 12rem 6rem;
  margin: 8rem 0 12rem;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  min-width: 320px;
}
#forgot-pw-terms {
  color: var(--green);
}

@media only screen and (max-width: 768px) {
  .forgot-password-component {
    width: 90%;
    border-radius: 28px;
    padding: 4rem 2rem 3rem;
    margin: 4rem 0 6rem;
  }
}
.price-dropdown {
  position: absolute;
  left: 25%;
  transform: translateX(-35%);
  margin-top: 1rem;
  z-index: var(--z-index-modal);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
  padding: 20px;
  width: 350px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price-dropdown p {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.price-button-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-slider-price {
  display: flex;
}

.price-dropdown hr {
  margin-top: 1rem;
}

.price-dropdown select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: var(--font-size-xx-small);
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.price-slider-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.price-slider .price-slider-container {
  max-width: 100%;
}

@media only screen and (max-width: 430px) {
  .price-dropdown {
    left: 35%;
    width: 340px;
  }
}
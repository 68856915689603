.terms-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.terms-inner {
  padding: var(--spacing-lg);
}

.terms-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.wp-block-heading:nth-child(1) {
  font-size: var(--font-size-medium);
  font-weight: 500;
  color: var(--green);
}

.wp-block-heading:nth-child(2) {
  font-size: var(--font-size-x-small);
  padding-bottom: 2.5rem;
  font-weight: 400;
}

.terms-page h3 {
  font-size: var(--font-size-medium);
  font-weight: 700;
}

.terms-page h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  margin-top: 0;
  padding-right: 2rem;
  margin-bottom: 2.8rem;
  /* white-space: nowrap; */
}

.terms-page h3 {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 2.5rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
  /* white-space: nowrap; */
}

.terms-page p {
  font-size: var(--font-size-x-small);
  padding-bottom: 2.5rem;
}

.terms-page a {
  color: var(--green);
}

.terms-page ul {
  margin-left: 2rem;
  padding-bottom: 2.5rem;
}

.terms-page li {
  font-size: var(--font-size-xx-small);
}

.terms-page-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1580px) {
  .terms-page {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }

  .terms-main-text {
    width: 100%;

    p {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 500px) {
  .terms-page {
    padding: 1rem;
  }

  .terms-page h1 {
    font-size: var(--font-size-medium);
  }

  .terms-page h3 {
    font-size: var(--font-size-small);
  }

  .terms-page h2 {
    font-size: var(--font-size-small);
  }
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
}

*:focus-visible {
  outline: none;
  border: 2px solid var(--green);
}

*:focus {
  outline: none;
}

*:hover {
  outline: none;
  /* Remove black borders from inputs etc. */
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Outfit";
  src: url(./assets/fonts/Outfit-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Lato";
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "PT-Sans";
  src: url(./assets/fonts/PTSans-Regular.ttf);
}

/* main {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
} */

:root {
  --gray-light: #f8f8f8;
  --gray-medium: #e2dfdf;
  --gray-dark: #7f8b94;
  --gray-black: #2e2e48;

  --text-black: #727275;
  --text-black: #393945;
  --text-black: #181818;

  --green: #4e8157;
  --green-light: #dae4dc;
  --pink: #fd6580;
  --red: #ff0000;
  --blue-light: #e4eaef;
  --white: #ffffff;
  --black: #000000;
  --orange: #fd7e14;

  /* Spacing, heights and widths */
  /* --navbar-height: 9rem; */

  --spacing-xxs: 1%;
  --spacing-xs: 2.05%;
  --spacing-sm: 3.125%;
  --spacing-md: 4.15%;
  --spacing-lg: 5.25%;
  --spacing-xl: 8.25%;
  --spacing-xxl: 11.45%;

  /* Shadows */
  --shadow-light: 0px 10px 24px #0000002e;
  --shadow-medium: 0px 8px 20px #00000029;

  /* General z-indexes */
  --z-index-base: 0;
  --z-index-above: 1;
  --z-index-below: -1;
  --z-index-auto: auto;

  /* Specific z-indexes */
  --z-index-popover: 1;
  --z-index-tooltip: 2;
  --z-index-navbar: 3;
  --z-index-backdrop: 4;
  --z-index-modal: 5;

  /* Typography */
  --montserrat: "Montserrat", sans-serif;
  --PT-sans: "PT Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --outfit: "Outfit", sans-serif;

  /* Fontsizes */
  /* mobile */
  --font-size-mobile-x-small: 2rem;
  --font-size-mobile-small: 2.7rem;
  --font-size-mobile-medium: 3.5rem;
  --font-size-mobile-large: 4.25rem;
  --font-size-mobile-x-large: 5.25rem;
  /* standard */
  --font-size-xx-small: 1.6rem;
  --font-size-x-small: 2rem;
  --font-size-small: 2.6rem;
  --font-size-medium: 3rem;
  --font-size-large: 4rem;

  /* Navbar standard height */

  --navbar-height: 7rem;
}

html {
  font-size: 62.5%;
  scroll-behavior: auto;
}

body {
  background: var(--white);
  color: var(--text-black);
  line-height: 1.5;
  font-family: var(--montserrat) !important;

  /* Add padding behind navbar */
  margin-top: 7rem !important;
  overflow-x: hidden !important;
  overflow-y: scroll;
}

main {
  /* min-height for each will show navbar and main content */
  min-height: calc(100vh - var(--navbar-height));
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-open {
  overflow: hidden;
}

.article-section {
  padding: var(--spacing-lg);
  margin-top: 2rem;
  margin-bottom: 4rem;
  position: relative;
}

.article-header {
  font-weight: 700;
  font-size: var(--font-size-medium);
  margin-bottom: 4rem;
}

.articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.article-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
}

.article-arrow {
  width: 3.8rem;
  height: 3.8rem;
  padding: 0.4rem;
  color: var(--text-gray);
  background-color: var(--white);
  border-radius: 50%;
  cursor: pointer;
}

.article-arrow-prev {
  padding-right: 0.6rem;
}

.article-arrow-next {
  padding-left: 0.6rem;
}

@media only screen and (max-width: 980px) {
  .article-header {
    padding-left: var(--spacing-lg);
    font-size: var(--font-size-mobile-small);
  }

  .articles {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 8rem;
  }

  .article-mobile-slider {
    overflow: hidden;
    background: #fff;
    width: 100%;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .articles {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 6rem;
  }

  .article-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

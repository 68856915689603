.map-section {
  height: 100vh;
}

.map-container {
  height: calc(100vh - var(--navbar-height));
  position: sticky;
  top: var(--navbar-height);
}

.custom-marker-cluster {
  background: #ffffff;
  border: 6px solid var(--green);
  border-radius: 50%;
  color: #000000;
  height: 45px !important;
  line-height: 31px;
  width: 45px !important;
  font-size: var(--font-size-xx-small);
  text-align: center;
  font-family: var(--montserrat);
  font-weight: 700;
}

.map-section-popup {
  font-family: var(--montserrat);
}

.leaflet-popup-content-wrapper {
  margin: 1px -7px 1px 20px;
  height: 100%;
}

.leaflet-popup-content {
  margin: 0px;
  width: 350px;
  height: fit-content;
}

.leaflet-popup-tip-container {
  margin-top: -2px;
  margin-left: -15px;
}

.leaflet-popup-close-button {
  color: #ffffff !important;
  right: -4px !important;
  display: none;
}

@media only screen and (max-width: 800px) {
  .map-section {
    height: 40vh;
  }

  .map-container {
    height: 40vh;
    position: static;
    top: 0;
  }
}

@media only screen and (max-width: 380px) {
  .map-placeholder {
    margin-left: 0.9rem;
  }
}

@media only screen and (max-width: 360px) {
  .map-placeholder {
    margin-left: 0.9rem;
  }
}

.steps {
  margin: auto;
  width: 100%;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.steps h2 {
  font-size: var(--font-size-small);
  font-weight: 800;
  padding-bottom: 1rem;
  color: var(--green);
  margin-bottom: 2rem;
  align-self: center;
  text-align: center;
}

.steps-container {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  width: 100%;
}

.steps-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--green);
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
}

.steps-circle img {
  width: 75px;
  height: 75px;
}

.steps-outer {
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.steps-outer span {
  font-weight: 700;
  font-size: var(--font-size-x-small);
}

.steps-heading {
  font-size: var(--font-size-x-small);
  font-weight: 600;
}

.steps-text,
.steps-top p {
  font-size: var(--font-size-xx-small);
  width: 30rem;
}

@media only screen and (max-width: 840px) {
  .steps-container {
    margin-top: 0rem;
    display: grid;
    grid-template-columns: 260px 260px;
    grid-template-rows: auto auto;
  }

  .steps-outer:nth-child(3) {
    grid-column: 1 / span 2;
    justify-self: center;
  }
}

@media only screen and (max-width: 570px) {
  .steps {
    height: auto;
    grid-template-rows: auto auto;
    margin-bottom: -4rem;
    display: inline;
  }

  .steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8rem;
  }

  .steps-container-carousel .slick-dots {
    padding-top: 20rem;
  }

  .steps-container-carousel {
    position: relative;
  }

  .steps-arrows {
    position: relative;
    bottom: 20rem;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
  }

  .steps-arrow {
    width: 3.8rem;
    height: 3.8rem;
    padding: 0.4rem;
    color: var(--text-gray);
    background-color: var(--white);
    border-radius: 50%;
    cursor: pointer;
  }

  .steps-outer {
    margin-top: 2rem;
  }

  .steps-outer:nth-child(3) {
    grid-column: 1;
    justify-self: center;
  }

  .steps-outer-carousel {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  .steps-container-carousel .slick-dots li.slick-active button::before {
    color: black;
  }

  .steps-container-carousel .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--white);
    border: none;
    box-shadow: none;
    outline: none;
  }

  .steps-outer-carousel span {
    font-size: var(--font-size-x-small);
    font-weight: 600;
  }

  .steps-outer-carousel b {
    font-size: var(--font-size-x-small);
    font-weight: 600;
  }

  .steps-outer-carousel p,
  .steps-top p {
    font-size: var(--font-size-xx-small);
  }

  .steps-text {
    margin: 0rem 2rem 0rem 2rem;
  }
}

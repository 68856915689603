.search-bar {
  display: flex;
  align-items: center;
  max-width: 100%;
  border-radius: 50px;
  border: 1px solid var(--text-black);
  background-color: var(--white);
  overflow: hidden;
  box-sizing: border-box;
  height: 4rem;
  margin-bottom: 0.5rem;
}

.search-bar input {
  flex-grow: 1;
  min-width: 0;
  border: none;
  padding-left: 2.5rem;
  font-size: var(--font-size-xx-small);
  box-sizing: border-box;
}

.search-bar button {
  justify-self: stretch;
  padding: 1.45rem 1.75rem 1.05rem 0.5rem;
  border: none;
  font-size: var(--font-size-xx-small);
  font-weight: 400;
  white-space: nowrap;
  background-color: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--black) !important;
}

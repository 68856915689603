/* Base styles */
.footer {
  padding: 3.5rem var(--spacing-xxl);
  background-color: var(--gray-light);
  overflow: hidden;
}

.footer-inner {
  display: grid;
  grid-template-columns: auto auto auto;
  padding-right: var(--spacing-sm);
  padding-bottom: var(--spacing-lg);
  justify-items: center;
}

.footer-inner img {
  max-width: 14rem;
}

.footer-column span,
.footer-column-support span,
.footer-column-follow span {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  text-align: left;
}

.footer-column ul,
.footer-column-support ul,
.footer-column-follow ul {
  list-style: none;
  padding-left: 0;
}

.footer-column li,
.footer-column-support li,
.footer-column-follow li {
  cursor: pointer;
  padding: 0.5rem 0;
}

.footer-column li>*,
.footer-column-support li>*,
.footer-column-follow li>* {
  font-size: var(--font-size-xx-small);
  text-decoration: none;
  color: var(--text-black);
  transition: all 0.15s;
  white-space: nowrap;
}

.footer-column li>*:hover,
.footer-column-support li>*:hover,
.footer-column-follow li>*:hover {
  color: var(--text-black);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
  border-top: 1px solid var(--text-black);
}

.footer-bottom p {
  font-size: 2rem;
  color: var(--text-black);
  margin-bottom: 0;
}

.footer-bottom .vakansa-logo {
  width: 15rem;
}

.footer-column-follow li {
  display: flex;

  align-content: flex-end;
}

.footer-logo {
  width: 15rem;
}

/* Media queries */
@media only screen and (max-width: 980px) {
  .padding {
    padding: 5rem var(--spacing-xl);
  }

  .footer-inner {
    column-gap: 2rem;
    padding-bottom: var(--spacing-sm);
    padding-right: 0;
  }
}

@media only screen and (max-width: 720px) {
  .footer-inner {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-inner img {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .footer {
    padding: 3.5rem 2rem;
    overflow: hidden;
  }

  .footer-inner {
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    justify-self: center;
  }

  .footer-inner img {
    display: block;
  }

  .footer-column-support {
    margin-left: 0px;
  }

  .footer-bottom {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom p:first-child {
    margin-bottom: 1rem;
  }

  .footer-column-follow {
    grid-column: 1 / span 2;
  }

  .footer-column-follow span {
    display: none;
  }

  .footer-column-follow ul {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }

  .footer-column-follow ul :nth-child(2) {
    position: relative;
    left: 10px !important;
  }
}
.ad-map-slim {
  width: 100%;
}

.ad-page hr {
  border: none;
  border-top: 1px solid var(--gray-medium);
}

.ad-map-slim .leaflet-container {
  margin-top: 2rem;
  height: 400px !important;
}

#root {
  background-color: var(--gray-light);
}

.ad-page {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  padding: 1rem var(--spacing-xs) 8rem;
  max-width: 1600px;
  margin: 0 auto;
}

#ad-page-body {
  max-width: 1620px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.ad-page-carousel-top-wrapper {
  display: grid;
  grid-template-columns: 3fr minmax(360px, 1fr);
  column-gap: 2rem;
}


#ad-page-info {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  max-width: 1200px;
}

.ad-page section {
  padding: 2rem 0.8rem;
  border-bottom: 1px solid #c4c3c3;


}

.ad-page h3 {
  font-size: var(--font-size-small);
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.ad-page section &:last-child {
  border-bottom: none;
}

.heading-request {
  font-size: var(--font-size-xx-small);
  grid-column: 1 / span 3;
}

.heading-request p {
  margin-top: 4rem;
}

.leaflet-pane, .leaflet-control-attribution, .leaflet-touch {
  z-index: 0;
}


@media only screen and (max-width: 1560px) {
  #ad-page-body {
    grid-template-rows: 60rem auto;
  }

  #ad-page-info {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }
}

@media only screen and (max-width: 980px) {
  #ad-page-body {
    grid-template-columns: 1fr;
    grid-template-rows: 47rem auto;
    gap: 2.4rem;
  }

  #ad-page-images & div {
    height: 100%;
  }

  #ad-page-booking {
    grid-column: span 1;
    grid-row: 2 / 3;
    justify-self: end;
    align-self: stretch;
  }
}

@media only screen and (max-width: 600px) {
  #ad-page-body {
    grid-template-rows: 40rem auto;
  }

  .ad-page {
    padding: 1rem var(--spacing-xs) 8rem;
  }
}
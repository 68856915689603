.ad-card-component {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 33rem;
  height: 34.5rem;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 7px;
  overflow: visible;
  cursor: pointer;
}

.ad-card-component h5 {
  font-size: 2rem;
  line-height: 2rem;
  color: var(--text-black);
}

.ad-card-component p {
  font-size: 1.6rem;
  color: var(--text-black);
}

.ad-card-component p, .ad-card-component h5 {

  margin: 0;
}

.ad-card-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 7px 7px 0 0;
}

.ad-card-image img {
  object-fit: cover;
  width: 100%;
  height: 15rem;
}

.ad-card-status {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2.8rem;
  text-align: center;
  background-color: white;
  bottom: 0px;
  width: 100%;
  border-radius: 0px;
}

.ad-card-status p {
  font-size: 1.8rem;
  line-height: 1.55;
}

.ad-card-status-active {
  color: var(--green) !important;
}

.ad-card-status-inactive {
  color: var(--red) !important;
}


.ad-card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 3rem 2.5rem;
}

.ad-card-heading h2 {
  font-size: var(--font-size-xx-small);
}

.ad-card-heading p,
.ad-card-heading h5 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--font-size-xx-small);
}

.ad-card-button-group {
  position: absolute;
  top: 0.8rem;
  right: 0.6rem;
  z-index: var(--z-index-popover);
}

.ad-card-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.6rem;

  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--text-black);
  text-decoration: none;
  background-color: var(--white);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;

  transition: opacity 0.15s ease-in;

}

.ad-card-tooltip {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  z-index: var(--z-index-tooltip);
  background-color: var(--green);
  border-radius: 4px;
}

@media only screen and (max-width: 1310px) {
  .ad-card-component {
    width: 30rem;
    height: 32rem;
  }
}

@media only screen and (max-width: 980px) {
  .ad-card-component {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .ad-card-button {
    opacity: 1;
    width: 4rem !important;
  }

  .ad-card-tooltip {
    visibility: hidden;
  }

  .ad-card-tooltip & div {
    width: 100%;
  }

  .ad-card-image img {
    min-height: 20rem;
    max-height: 35rem;
  }


  .ad-card-button-group {
    display: flex;
    align-items: flex-end;
  }

  .ad-card-button-group &>div {
    flex-direction: column;
  }


  .ad-card-status {
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }

  .ad-card-info {
    display: block;
  }
}
.my-ads-header-section {
  width: 34rem;
  padding-bottom: 2rem;
}

.my-ads-header-section h1 {
  white-space: nowrap;
}

.my-ads-header-section h1,
.my-ads-header-section h2 {
  font-size: var(--font-size-medium);
  font-weight: 900;
  font-family: var(--montserrat);
  margin-bottom: 0;
}

.my-ads-header-section h4 {
  font-size: 2.3rem;
  white-space: nowrap;
}

.my-ads-header-section div {
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  width: 100%;
}

.my-ads-header-section p {
  font-size: 1.5rem;

}

.my-ads-header-section div>span {
  border: 1px solid var(--text-black);
  border-radius: 2px;
  margin: 10px 0;
}

.separation-line {
  width: 100%;
  height: 3px;
  background: #DEDAD7;
  margin: 15px 0;
}

.card-filter {
  grid-column: 1 / span 3;
  grid-row: 1 / 2;
  justify-self: center;
}

.card-filter button {
  flex-basis: auto;
  font-size: 1.8rem;
  font-weight: 700;
  border: none;
  background-color: transparent;
  color: var(--text-black);
  padding: 0.5rem 2rem;
  border-radius: 24px;
  white-space: nowrap;
  cursor: pointer;
}

.filter-button-active {
  color: var(--white) !important;
  background-color: var(--green) !important;
  box-shadow: var(--shadow-light) !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 980px) {
  .my-ads-header-section {
    width: 100%;
    padding-bottom: 0;
  }

  .my-ads-header-section span {
    display: none;
  }

  .my-ads-header-section div {
    justify-content: space-around;
  }

  .my-ads-header-section div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .my-ads-header-section h2 {
    font-size: 4rem;
  }

  .my-ads-header-section p {
    margin: 0;
    font-size: 2rem;
  }
}

@media only screen and (max-width:300px) {
  .my-ads-header-section h2 {
    font-size: 3rem;
  }

  .my-ads-header-section p {
    font-size: 1.5rem;
  }
}


.my-ads-card-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22.5%, 33rem));
  gap: 3.5rem max(2rem, 3%);
}

.my-ads-card-section h4 {
  font-size: 2.8rem;
  font-weight: 700;
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.card-filter {
  grid-column: 1 / span 3;
  grid-row: 1 / 2;
  justify-self: center;
}

.card-filter button {
  flex-basis: auto;
  font-size: 1.8rem;
  font-weight: 700;
  border: none;
  background-color: transparent;
  color: var(--text-black);
  padding: 0.5rem 2rem;
  border-radius: 24px;
  white-space: nowrap;
  cursor: pointer;
}

.filter-button-active {
  color: var(--white);
  background-color: var(--green);
  box-shadow: var(--shadow-light);
  cursor: pointer;
}


@media only screen and (max-width: 1350px) {
  .my-ads-card-section {
    gap: 3rem max(1rem);
  }

  .card-filter {
    grid-column: 3 / 3;
    grid-row: 1 / 2;
    justify-self: center;

  }
}

@media only screen and (max-width: 1300px) {
  .my-ads-card-section {
    grid-template-columns: repeat(auto-fit, minmax(10.5%, 30rem));
  }
}

@media only screen and (max-width: 980px) {
  .my-ads-card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my-ads-card-section, .my-ads-card-button-group button {
    width: 100%;
    text-align: center;
  }

  .card-filter {
    display: flex;
  }
}

.create-ad-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33rem;
  height: 34.5rem;
  background-color: var(--white);
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.create-ad-card img {
  width: 16rem;
}

.create-ad-card p,
.create-ad-card svg {
  font-size: var(--font-size-small);
  font-weight: 700;
  color: var(--text-black);
  text-decoration: none;
}

@media only screen and (max-width: 1310px) {
  .create-ad-card {
    width: 30rem;
    height: 32rem;
  }
}

@media only screen and (max-width: 980px) {
  .create-ad-card {
    flex-direction: row;
    align-self: flex-start;
    width: auto;
    align-items: center;
    gap: 15px;
    height: 100%;
    box-shadow: none;
  }

  .create-ad-card img {
    width: 12rem;
    box-shadow: var(--shadow-medium);
    border-radius: 7px;
  }
}
.request-form-section {
  padding: var(--spacing-lg) var(--spacing-sm);
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

/* .request-form-subheading {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 2rem !important;
} */

.request-form-checkbox-text {
  margin-top: 0.5rem;
}

.request-form-section form {
  max-width: 90rem;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.request-form-name input,
.request-form-email-number input,
.request-form-message input {
  height: 4rem;
  padding: 0 2.5rem;
  border-radius: 50px;
  font-size: 1.6rem;
  border: 1px solid #ced4da;
  width: 100%;
}

.request-form-email-number {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.request-form-message textarea {
  height: 15rem;
  font-size: 1.6rem;
  border-radius: 23px;
  padding: 1.5rem 2.5rem;
  border: 1px solid #ced4da;
  width: 100%;
}

.request-form-section p {
  font-size: var(--font-size-xx-small);
  padding-top: 0rem;
  margin-bottom: 0.5rem;
}

.request-form-section h2 {
  /*  margin: 0 auto; */
  font-size: var(--font-size-medium) !important;
  text-align: left;
}

.request-form-section h1 {
  font-size: var(--font-size-large);
  color: var(--green);
  text-align: left;
}

.request-form-checkbox {
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 35px 30px;
  gap: 0px 10px;
  accent-color: var(--green);
}

.request-form-checkbox span {
  grid-column: span 2;
  font-size: 1.4rem !important;
}

.request-form-name span,
.request-form-email-number span,
.request-form-message span,
.request-form-checkbox span {
  font-size: 1.4rem;
  color: var(--red);
}

.request-form-send-button-container {
  display: flex;
  justify-content: center;
}

.request-form-send-button-container p {
  font-size: var(--font-size-x-small);
  font-weight: 500;
}

.request-form-checkbox span {
  font-size: var(--font-size-xx-small);
}

.request-form-checkbox a {
  color: var(--green);
}

.request-form-checkbox-button {
  display: flex;
  align-items: center;
  gap: 4px;
}

.request-form-checkbox-button input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.request-form-checkbox-other {
  display: flex;
  align-items: center;
  gap: 4px;
}

.request-form-radio {
  display: flex;
  align-items: center;
  gap: 4px;
}

.request-form-radio input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.request-form-radio-other {
  display: flex;
  align-items: center;
  gap: 4px;
}

.request-form-radio-other-radio {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.request-form-radio-other-text {
  height: 4rem;
  padding: 0 2.5rem;
  border-radius: 50px;
  font-size: 1.6rem;
  border: 1px solid #ced4da;
  width: 25rem;
}

.request-form-error-msg {
  display: flex;
  font-size: var(--font-size-x-small);
  justify-content: center;
  color: var(--red);
}

@media only screen and (max-width: 580px) {
  .request-form-email-number {
    grid-template-columns: 100%;
  }
}

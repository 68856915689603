.article-card {
  width: 100%;
  color: var(--gray-black);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.article-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.article-card img {
  height: 48rem;
  width: 100%;
  object-fit: cover;
  border-radius: 26px;
}

.article-card h3 {
  font-size: var(--font-size-x-small);
  font-weight: 700;

  margin-top: 2rem;
}

.article-card p {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  color: var(--gray-dark);
  padding-right: 2rem;
}

@media only screen and (max-width: 980px) {
  .article-card p {
    padding-right: 0;
  }

  .article-card h3 {
    font-size: var(--font-size-mobile-small);
  }
}
.warning-modal-component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  min-width: 200px;
  padding: var(--spacing-lg);
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;
}

.warning-modal-component h2 {
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 1.75rem;
  white-space: nowrap;
}

.warning-modal-component p {
  font-size: 1.8rem;
  line-height: 25px;
  overflow-wrap: break-word;
  margin-bottom: 1rem;
}

.warning-modal-component span {
  font-size: 1.8rem;
  font-weight: 700;
  overflow-wrap: break-word;
  color: var(--red);
}

.warning-modal-component button {
  cursor: pointer;
}

.warning-modal-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.warning-modal-btn-wrapper {
  width: auto;
}

.warning-modal-btn {
  min-width: 17.5rem;
  padding: 1.75rem 5rem;
  white-space: nowrap;
  margin: 0 0.7rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
  border-radius: 29px;
  border: none;
  background-color: var(--green);
  color: var(--white);
}
.warning-modal-btn-cancel {
  color: var(--text-black);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
}

.warning-modal-btn-confirm {
  color: var(--white);
  background-color: var(--red);
}

.warning-modal-close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 8rem;
  width: 8rem;
  background: none;
  border: none;
}

@media only screen and (max-width: 550px) {
  .warning-modal-component {
    width: 430px;
  }
}

@media only screen and (max-width: 550px) {
  .warning-modal-component {
    width: 430px;
  }
}

@media only screen and (max-width: 450px) {
  .warning-modal-component {
    width: 400px;
  }
}

@media only screen and (max-width: 430px) {
  .warning-modal-component {
    width: 365px;
  }

  .warning-modal-component h2 {
    margin-top: 4.75rem;
    margin-bottom: 0.2rem;
  }

  .warning-modal-btn-row {
    flex-direction: column;
    gap: 1rem;
  }
}

.warning-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  cursor: pointer;
  background-color: var(--gray-black);
  opacity: 0.5;
}

.square-button {
  color: var(--white);
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  padding: 0.5rem 2.45rem 1.05rem;
  background-color: var(--green);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  max-width: 300px;
}

.square-button-text {
  margin-top: 3px;
}

.square-button.orange-border {
  border: 1px solid var(--orange);
}

.square-button:hover {
  box-shadow: var(--shadow-medium);
  transform: scale(1.05);
}

.square-button.sm {
  max-width: 100px;
}


.square-button.md {
  max-width: 200px;
}


.square-button.lg {
  max-width: 300px;
}
.text-button {
  cursor: pointer;
  font-size: var(--font-size-x-small);
  text-decoration: none;
  transition: all 0.15s;
  white-space: nowrap;
}

a:hover {
  color: var(--text-black);
}

.text-button.xxs {
  font-size: var(--font-size-xx-small);
}

.text-button.sm {
  font-size: var(--font-size-x-small);
}

.text-button.md {
  font-size: var(--font-size-small);
}

.text-button.lg {
  font-size: var(--font-size-medium);
}

.text-button.green {
  color: var(--green);
  text-decoration: underline 2px;
}

.text-button.white {
  color: var(--text-black);
}

.eva {
  background-color: blue;
  color: white;
}
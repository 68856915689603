.ad-component {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  padding: 3rem var(--spacing-xs) 8rem;
  background-color: var(--gray-light);
}

.watermark {
  position: fixed;
  top: 35%;
  left: 20%;
  opacity: 0.4;
  z-index: var(--z-index-above);
  transform: rotate(-35deg);
  color: var(--pink);
  font-size: 15rem;
}

#ad-preview-nav p>button {
  border: none;
  background: none;
  margin: 0;
  cursor: pointer;
  font-size: var(--font-size-xx-small)
}


.ad-page-info-prev {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 980px) {
  .watermark {
    font-size: 7.5rem;
    left: 10%;
  }
}

@media only screen and (max-width: 480px) {
  .watermark {
    font-size: 7.2rem !important;
    left: 0%;
  }
}


#ad-page-preview-body {
  max-width: 1620px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr minmax(360px, 1fr);
  grid-template-rows: 66.2rem auto;
  column-gap: 2.5%;
}

#ad-page-images {
  grid-column: 1 / 2;
}

#ad-page-booking {
  grid-column: 2 / 3;
}

#ad-page-info {
  grid-row: 2 / 3;
}

.ad-component section {
  padding: 2rem 0.8rem;
  border-bottom: 1px solid #c4c3c3;



  &:last-child {
    border-bottom: none;
  }
}

.ad-component h3 {
  font-size: 3rem;
  font-weight: 700;

  margin-bottom: 2.5rem;
}

@media only screen and (max-width: 1560px) {
  #ad-page-preview-body {
    grid-template-rows: 60rem auto;
  }

  #ad-page-info {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }
}

@media only screen and (max-width: 980px) {
  #ad-page-preview-body {
    grid-template-columns: 1fr;
    grid-template-rows: 47rem auto;
    gap: 2.4rem;
  }

  #ad-page-images {
    grid-column: span 1;

    & div {
      height: 100%;
    }
  }

  #ad-page-booking {
    grid-column: span 1;
    grid-row: 2 / 3;
    justify-self: end;
    align-self: stretch;
  }

}

@media only screen and (max-width: 600px) {
  #ad-page-preview-body {
    grid-template-rows: 40rem auto;
  }
}
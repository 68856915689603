.facilities-top-text {
  font-size: var(--font-size-xx-small);
  line-height: 39px;
  white-space: pre-line;
  font-weight: 700;
}

.facilities-section table {
  width: 100%;
}

.facilities-section tbody {
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  column-gap: 5rem;
  row-gap: 2rem;
}

.facilities-section tr {
  display: flex;
  align-items: center;
}

.facilities-section img {
  height: 2.25rem;
  width: auto;
  margin-right: 0.75rem;
}

.facilities-section td {
  font-size: var(--font-size-xx-small);
}

@media only screen and (max-width: 720px) {
  .facilities-section tbody {
    grid-template-columns: repeat(auto-fill, 32%);
    column-gap: 2%;
  }
}

@media only screen and (max-width: 580px) {
  .facilities-section tbody {
    grid-template-columns: repeat(auto-fill, 40%);
    column-gap: 0%;
  }

  .facilities-section img {
    height: 2.25rem;
    width: auto;
    margin-right: 0.1rem;
  }
}

@media only screen and (max-width: 430px) {
  .facilities-section tbody {
    margin-top: 1.5rem;
    column-gap: 0.16rem;
    row-gap: 2rem;
  }
}

.mobile-search-result-card {
  width: fit-content !important;
  min-width: 250px;
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: var(--shadow-light);
  height: 25rem;
  background-color: #FAFAFA;
  transition: all 250ms ease-in-out;
  color: black;
}

.mobile-ad-card-outer-link {
  text-decoration: none;
  color: black;
}

.mobile-search-result-card:hover.true {
  transform: scale(1.05);
  transition: all 150ms ease-in-out;
}

.mobile-search-result-card:hover.false {
  transform: scale(0);
  transition: none;
}

.mobile-search-ad-card {
  display: flex;
  flex-direction: column;
  padding: 2rem 0.6rem;
  width: 100%;
}

.mobile-search-ad-card-heading {
  margin-top: -1.7rem;
}

.mobile-search-ad-card p {
  font-size: 1.6rem;
  padding-bottom: 10px;
  font-weight: 300;
  margin: 0;
}

.mobile-search-ad-card h4 {
  font-size: 1.6rem;
  font-weight: 500;
  color: #222;
  width: 100%;
}

.mobile-search-ad-card-info {
  margin-top: -1rem;
  font-size: 1.5rem;
}

.mobile-adcard-image {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 15rem;
  aspect-ratio: 16 / 9;
  background-color: var(--blue-light);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;

}

.mobile-adcard-no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 15rem;
  background-color: var(--blue-light);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;
  margin-bottom: 8px;
}

.mobile-slash-eye-icon {
  width: 100% !important;
  height: 10rem;
  align-self: center;
  fill: var(--text-black);
}

.mobile-search-ad-card-price {
  min-height: 5rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mobile-search-ad-card-price span {
  display: flex;
  font-weight: 600;
  font-size: 1.4rem
}

.leaflet-popup-content {
  width: fit-content !important;
}

.mobile-search-ad-card-price p {
  font-weight: 600;
  font-size: 1.5rem;
}

.mobile-search-ad-card-price {
  min-height: 2rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 380px) {
  .mobile-search-result-card {
    width: 95%;
  }
}
.municipality-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  width: 30rem;
  background-color: var(--white);
  border-radius: 28px;
  padding: 2rem 2rem 0;
}

.municipality-search {
  height: 4rem;
  padding: 0 2.5rem;
  border-radius: 50px;
  font-size: 1.6rem;
  border: 1px solid #ced4da;
  width: 70%;
}

.searchResults-modal-checkboxes {
  height: 40rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 2rem;
  padding: 0 2% 0 2%;
  overflow-y: auto;
}


#searchResults-modal-buttons {
  padding: 0 0 0.75rem;
  height: 9.65rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e2dfdf;
}

.municipality-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);

  background-color: var(--gray-black);
  opacity: 0.5;
}
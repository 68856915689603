.about-us-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.about-us-inner {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 4rem;
}

.about-us-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}

.about-us-intro-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
}

.about-us-intro-text h1 {
  color: var(--green);
  font-size: var(--font-size-large);
}

.about-us-intro-text h2 {
  font-size: var(--font-size-medium);
  text-align: left;
  margin-bottom: 1.2rem;
  white-space: wrap;
}

.team-section {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.team-section h4 {
  text-align: center;
  font-size: var(--font-size-medium);
}

.about-us-team {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.about-us-intro-text p {
  margin: 0;
  font-size: var(--font-size-xx-small);
}

.about-us-founder-section-text a {
  color: var(--green);
}

.about-us-readmore-button {
  font-size: var(--font-size-x-small);
  border: medium;
  background-color: transparent;
  font-weight: bold;
  text-decoration: underline;
}

.about-us-intro-video {
  margin-top: 5.25rem;
  justify-self: center;
  padding-top: 4rem;
  width: 100%;
  max-width: 70%;
}

@media only screen and (max-width: 980px) {
  .about-us-intro-video {
    max-width: 100%;
    padding-top: 2rem;
  }
}

.vision-section {
  display: block;
  padding-top: var(--spacing-xl);
  align-self: self-start;
}

.about-us-vision-image {
  float: right;
  align-self: self-end;
  padding: 3rem 0rem 0 2rem;
}

.about-us-vision-image img {
  height: 25rem;
}

/* .about-us-vision-text {
  margin-right: 5%;
} */

.about-us-vision-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 1.2rem;
}

.about-us-vision-text p {
  font-size: var(--font-size-xx-small);
}

.video-card-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-card-container video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 1720px) {
  /* .vision-section {
    display: block;
  }

  .about-us-vision-image {
    float: right;
    padding: 4rem 4rem 0 1.25rem;
  }

  .about-us-vision-image img {
    height: 25rem;
  }

  .about-us-vision-text,
  .about-us-vision-image {
    margin: 0;
  } */
}

@media only screen and (max-width: 1200px) {
  .about-us-vision-image {
    display: none;
  }
}

@media only screen and (max-width: 1040px) {
  .about-us-inner {
    padding: 0;
  }
}

.about-us-founder-section {
  display: grid;
  grid-template-columns: 42.25% 57.25%;
  padding-top: var(--spacing-xl);
  padding-bottom: 4rem;
}

.about-us-founder-section h2 {
  margin-bottom: 1.2rem;
  text-align: left;
  font-size: var(--font-size-medium);
  color: var(--green);
}

.about-us-founder-section p {
  font-size: var(--font-size-xx-small);
}

.about-us-founder-image,
.about-us-founder-image-mobile {
  justify-self: center;
  align-self: center;
  width: 50rem;
  margin-left: 8rem;
}

.about-us-founder-image img {
  width: 100%;
  height: auto;
  transition: transform 250ms ease-in-out;
}

.about-us-founder-image img:hover {
  transform: scale(1.04);
  transition: transform 150ms ease-in-out;
}

.about-us-founder-image-mobile {
  /* image for mobile only */
  display: none;
}

.about-us-founder-section-text h3 {
  font-size: var(--font-size-x-small);
}

.vision-section .about-us-vision-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 1.2rem;
}

@media only screen and (max-width: 1320px) {
  .founder-section {
    grid-template-columns: 1fr min-content;
  }

  .about-us-founder-image {
    width: 40rem;
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 980px) {
  .about-us-vision-text h2 {
    white-space: normal;
    font-size: 3.5rem;
    margin: 0px;
    padding: 0px;
  }
  .founder-section {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }

  .about-us-founder-image {
    display: none;
  }

  .about-us-founder-image-mobile {
    display: flex;
    float: right;
    width: 100%;
  }

  .about-us-founder-image-mobile img {
    width: 75%;
    max-width: 350px;
    margin: 0 auto 20px auto;
  }

  .about-us-founder-section-text {
    text-align: center;
    width: 100%;
  }

  .about-us-founder-section {
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-min-content;
    grid-template-rows: min-content;
  }

  .about-us-founder-section-text h2 {
    text-align: center;
  }

  .about-us-founder-section-text h3 {
    font-size: var(--font-size-x-small);
  }

  .about-us-intro-text h1 {
    font-size: var(--font-size-mobile-large);
  }

  .about-us-intro-text h2 {
    font-size: var(--font-size-mobile-small);
  }

  .team-section h2 {
    margin: 0px;
    padding: 0px;
    font-size: var(--font-size-mobile-small);
  }

  .vision-section .about-us-vision-text h2 {
    font-size: var(--font-size-mobile-small);
  }

  .founder-section h2 {
    font-size: var(--font-size-mobile-large);
  }
}

@media only screen and (max-width: 416px) {
  .about-us-vision-text {
    width: 330px;
  }
}

@media only screen and (max-width: 399px) {
  .about-us-vision-text {
    width: fit-content;
  }
}

.not-found {
  padding-top: var(--spacing-xl);
  padding-left: var(--spacing-xxl);
  background-color: var(--gray-light);
  padding-bottom: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.not-found h1 {
  font-size: var(--font-size-large);
  font-weight: 700;
  margin-bottom: 1rem;
}

.not-found p {
  font-size: var(--font-size-xx-small);
  color: var(--text-black);
  margin-bottom: var(--spacing-lg);
}

.not-found-btns {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
}

@media only screen and (max-width: 640px) {
  .not-found {
    padding-top: var(--spacing-xxl);
    padding-left: var(--spacing-lg);
    padding-bottom: 20rem;
  }

  .not-found h1 {
    font-size: var(--font-size-medium);
  }

  .not-found-btns {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 2rem;
  }
}
.faq-section-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.faq-cross-icon {
  margin-top: 2rem;
}

.faq-section-background {
  width: 100%;
  min-height: fit-content;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-background-img>img {
  width: 40vw;
  max-width: 500px;
  height: auto;
  padding: 0;
}

.faq-section {

  padding: var(--spacing-lg);
  margin: 0rem 2rem;
  width: 100%;
  justify-self: center;
}

.faq-section h1 {
  font-size: var(--font-size-large);
  color: var(--green);
  text-align: left;
}

.faq-section h2 {
  font-size: var(--font-size-medium);
  text-align: left;
}

.faq-section a {
  text-decoration: none;
  color: var(--green);
}

.faq-section-content {
  display: flex;
  flex-direction: row;
}

.faq-section-flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.faq-section-options p {
  font-size: var(--font-size-x-small);
}

.faq-section-options {
  text-align: center;
  width: 20vw;
  max-width: 200px;
  height: 20vw;
  max-height: 200px;
  background-color: white;
  border-radius: 20px;
  margin: 4rem 10vw 0 0;
}

.faq-section-options img {
  width: 140px;
  margin-top: 1.5rem;
}

.faq-section-options:hover {
  transform: scale(1.03);
  transition: 0.2s;
  cursor: pointer;
}

.faq-option-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#faq-item-1 {
  margin: auto 5rem auto;
}

.faq-info-background {
  position: absolute;
  left: 0;
  top: 20rem;
  width: 100vw;
  height: auto;
  background-color: var(--gray-light);
  padding-bottom: 70px;
}

.faq-info-wrapper {
  margin: auto;
  width: 83.5%;
  background-color: white;
  padding: var(--spacing-md);
  border-radius: 18px;
}

.faq-info-wrapper h2 {
  font-size: var(--font-size-x-small);
  font-weight: 900;
  color: black;
  margin-top: 4rem;
}

.faq-info-wrapper p {
  font-size: var(--font-size-xx-small);
}

.faq-info-wrapper ul {
  font-size: var(--font-size-xx-small);
  margin-left: 20px;
}

.faq-cross-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: fixed;
  cursor: pointer;
  right: 15rem;
  top: 21rem;
  padding: 3rem;
}

.faq-cross-background {
  background-color: rgba(0, 0, 0, 0.08);
  transition: background-color 0.5s ease-in-out;
  border-radius: 50%;
}

.faq-line {
  position: absolute;
  width: 70%;
  height: 3px;
  border-radius: 2px;
  background-color: black;
  transform: rotate(45deg);
}

.faq-line:nth-child(2) {
  transform: rotate(-45deg);
}

@media only screen and (max-width: 1500px) {
  .faq-section-content {
    flex-direction: column;
    align-items: end;
  }

  .faq-section-options {
    margin: 1rem auto;
  }
}

@media only screen and (max-width: 1060px) {
  .faq-background-img>img {
    display: none;
  }

  .faq-section-flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .faq-section-options {
    width: 200px;
    height: 200px;
    margin: 3rem auto;
  }

  .faq-section-content {
    text-align: left;
  }

  .faq-section-options img {
    width: 100px;
    margin-top: 3rem;
  }

  .faq-section {
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }

  .faq-section h1 {
    text-align: center;
  }

  .faq-section h3 {
    text-align: center;
  }

  .faq-section h2 {
    text-align: center;
  }

  .faq-cross-icon {
    width: 35px;
    height: 35px;
    right: 2rem;
    bottom: 0rem;
  }

  .faq-line {
    height: 2px;
  }

  .faq-info-wrapper {
    width: 91.5%;
    text-align: left;
  }

  .faq-info-wrapper h2 {
    text-align: left;
    text-overflow: clip;
    white-space: normal;
  }

  .faq-cross-icon {
    right: 4rem;
  }
}

@media only screen and (max-width: 896px) {
  .faq-cross-icon {
    right: 2rem;
  }

  .faq-section-top-text {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: start;
  }

  .faq-section {
    padding: 0;
  }

  .faq-section-top-text h2 {
    font-size: var(--font-size-mobile-small);
  }
}
.feedback-modal-outer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-modal);
  width: 430px;
  padding: 3rem 2rem;
  background-color: var(--white);
  border-radius: 28px;
  text-align: center;

}

.feedback-modal-outer h1 {
  font-size: var(--font-size-small);
}

.feedback-select-wrapper {
  position: relative;


}

.feedback-select-wrapper select {
  width: 100%;
  height: 4rem;
  font-size: 1.8rem;
  border: 1px solid #c6cbcf;
  border-radius: 100px;
  background: var(--white);
  padding: 0 2.5rem;
  appearance: none;
}

.feedback-select-wrapper img {
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.feedback-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-backdrop);
  cursor: pointer;
  background-color: var(--gray-black);
  opacity: 0.5;
}

.feedback-modal-button {
  color: var(--white);
  background-color: var(--green);
  border: medium none;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: var(--font-size-xx-small);
  width: 20rem;
  height: 4rem;
}

/* ----------------- FORM --------------- */

.sorry-inner {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0rem;
  background-color: var(--white);
}

.sorry-inner h2 {
  font-size: var(--font-size-medium);
}

.sorry-form-checkbox-button {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 5px;
}

.sorry-form-checkbox-button input {
  accent-color: var(--green);
  height: 23px;
  width: 23px;
  margin-top: -7px;
}

.sorry-form-checkbox-button p {
  font-size: var(--font-size-xx-small);
}

.sorry-form-custom-message p {
  font-size: var(--font-size-xx-small);
  font-weight: 600;
  margin-top: 0rem;
}

.error-message {
  color: var(--red);
}

.email-form-send {
  display: none;
}

.sorry-form-custom-message textarea {
  font-size: var(--font-size-xx-small);
  border: 1px solid #ced4da;
  width: 100%;
  background-color: #ffff;
  min-height: 8rem;
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.sorry-form-button {
  display: flex;
  justify-content: center;
}

.sorry-form-button p {
  font-size: var(--font-size-x-small);
  font-weight: 700;
}

@media only screen and (max-width: 440px) {
  .sorry-inner {
    display: flex;
    align-items: center;
  }

  .feedback-modal-button {
    color: var(--white);
    background-color: var(--green);
    border: medium none;
    border-radius: 50px;
    font-weight: 500;
    cursor: pointer;
    font-size: var(--font-size-xx-small);
  }
}

@media only screen and (max-width: 450px) {
  .sorry-form-checkbox-button p {
    text-align: left;
  }

  .feedback-modal-outer {
    width: 360px;

  }

}
.rounded-button {
  color: var(--white);
  background-color: var(--green);
  border: medium none;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: var(--font-size-xx-small);
}

.rounded-button.green {
  color: var(--white);
  background-color: var(--green);
}

.rounded-button.white {
  background-color: var(--white);
  border: 2px var(--green) solid;
  color: var(--green);
}

.rounded-button.whitest {
  background-color: var(--white);
  border: none;
  color: inherit;
  font-weight: 400;
  box-shadow: var(--shadow-light);
}

.rounded-button.red {
  background-color: var(--red);
  border: 2px var(--green) solid;
  color: var(--white);
  border: medium none;
}

.rounded-button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.rounded-button.xs {
  width: 10rem;
  height: 3rem;
}

.rounded-button.sm {
  width: 14rem;
  height: 4rem;
}

.rounded-button.md {
  width: 16.5rem;
  height: 4.5rem;
}

.rounded-button.lg {
  width: 24.5rem;
  height: 5rem;
}
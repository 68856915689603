.rent-vision {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  height: auto;
  gap: 6rem;
}

.rent-out {
  grid-row: 1;
  display: grid;
  grid-template-columns: auto auto;
  height: 400px;
}

.rent-out-text {
  margin-left: 8rem;
  max-width: 800px;
  margin-right: 4rem;
  align-self: center;
  justify-self: center;
}

.rent-out-text-mobile {
  white-space: wrap;
}

.rent-out-text h2,
.vision-text h2,
.rent-out-text-mobile h2,
.vision-text-mobile h2 {
  font-size: var(--font-size-small);
  font-weight: 800;
  padding-bottom: 1rem;
  color: var(--green);
}

.rent-out-text p,
.vision-text p,
.rent-out-mobile p,
.vision-mobile p {
  font-size: var(--font-size-xx-small);
}

.rent-out-image {
  place-self: end;
}

.vision {
  display: grid;
  grid-template-columns: 1fr auto;
}

.vision-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  opacity: 0.87;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  top: -230px;
  left: 160px;
}

.vision-circle img {
  width: 70px;
  margin-bottom: 10px;
}

.rent-vision-image {
  height: 350px;
  border-radius: 0px 1000px 1000px 0px;
}

.rent-out-image {
  height: 350px;
  border-radius: 1000px 0px 0px 1000px;
}

.vision-text {
  max-width: 800px;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 10rem;
  align-self: center;
  justify-self: center;
}

@media only screen and (max-width: 980px) {
  .vision {
    grid-template-columns: auto 280px;
    margin-bottom: 0rem;
  }

  .rent-vision {
    display: flex;
    flex-direction: column;
  }

  .vision-image-container {
    grid-column: 2;
    grid-row: 1;
  }

  .vision-text {
    grid-column: 1;
    margin-left: 2rem;
    margin-right: 1rem;
    width: auto;
    margin-bottom: 0rem;
  }

  .vision-circle {
    top: -230px;
    left: 120px;
  }

  .rent-vision-image {
    position: relative;
    height: 350px;
    width: 350px;
    object-fit: cover;
    border-radius: 1000px 1000px 1000px 1000px;
  }

  .rent-out-image {
    align-self: start;
    height: auto;
    position: relative;
    height: 350px;
    width: 350px;
    object-fit: cover;
    border-radius: 1000px 1000px 1000px 1000px;
    left: 70px;
  }

  .rent-out {
    grid-template-columns: auto 280px;
    height: auto;
    overflow-x: hidden;
  }

  .rent-out-text {
    margin-left: 2rem;
    margin-right: 1rem;
    width: auto;
  }
}

@media only screen and (max-width: 580px) {
  .rent-vision {
    align-items: center;
    justify-items: center;
    grid-template-rows: auto auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .rent-out {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: auto;
    gap: 2rem;
    justify-items: center;
  }

  .vision {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: auto;
    gap: 2rem;
    justify-items: center;
  }

  .vision-image-container {
    display: none;
  }

  /* .rent-out-mobile {
    display: grid;
    grid-template-columns: 180px 180px;
  } */

  .rent-out-text-mobile {
    grid-column: 1;
    grid-row: 1;
  }

  .rent-out-bottom-text-mobile {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  .rent-vision-image,
  .rent-out-image {
    /* place-self: start;
    height: 210px;
    width: 210px;
    object-fit: cover;
    border-radius: 1000px 1000px 1000px 1000px;
    left: 0px; */
    display: none;
  }

  /* .vision-mobile {
    display: grid;
    grid-template-columns: 180px 180px;
  } */

  .vision-text-bttom-mobile {
    grid-column: 1 / span 2;
  }

  .vision-circle {
    /* width: 100px;
    height: 100px;
    top: -155px;
    left: 55px; */
    display: none;
  }

  .vision-circle img {
    /* width: 65px;
    margin-bottom: 10px; */
    display: none;
  }
}

@media only screen and (max-width: 390px) {
  .rent-out-text-mobile,
  .rent-out-bottom-text-mobile,
  .vision-text-bttom-mobile,
  .vision-text-mobile {
    margin-left: 1rem;
  }
}

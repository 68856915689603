.blogpage-outer {
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.blogpage-inner {
  display: grid;
  grid-template-columns: auto 30rem;
  padding: var(--spacing-lg);
  max-width: 1600px;
}

.blogpage-container {
  margin: 2rem;
  overflow: hidden;
}

.blogpage-container h2 {
  font-size: var(--font-size-medium);
  margin-bottom: 0.5rem;
}

.blogpage-date {
  display: block;
  font-size: var(--font-size-xx-small);
  color: var(--gray);
  margin-bottom: 1rem;
  font-style: italic;
}

.blogpage-container p {
  font-size: var(--font-size-xx-small);
  line-height: 1.5;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  padding-bottom: 1rem;
}

.blogpage-container a {
  color: var(--green);
  font-size: var(--font-size-xx-small);
}

.blogpage-header {
  font-size: var(--font-size-large);
  color: var(--green);
}

.blogpage-container figure {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.blogpage-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 100%;
  display: block;
}

.blogpage-container>.rounded-link-button {
  margin-top: 40px;
}

.blog-page-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1040px) {
  .blogpage-inner {
    padding: 0;
  }
}

@media only screen and (max-width: 800px) {
  .blogpage-inner {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
.settings-container h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
}

.settings-container h3 {
  font-size: var(--font-size-small);
  margin-bottom: 4rem;
}

.settings-container button {
  cursor: pointer;
}

.settings-container div {
  padding-top: 1rem;
  margin-left: 10.5%;
  margin-right: 10.5%;
}

#settings-form-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5rem;
}

#settings-form-container form {
  width: 45rem;

  &:first-child {
    margin-right: 15rem;
  }
}

#settings-form-container label {
  font-size: 2rem;

  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

#settings-form-container input {
  width: 100%;
  height: 4rem;
  border: 1px solid var(--gray-dark);
  border-radius: 100px;
  padding: 0 2rem;
  margin-bottom: 1.7rem;
  font-size: 1.6rem;
}

#settings-form-container p {
  font-size: 1.8rem;

  margin-left: 0.5rem;
  white-space: nowrap;
}

.settings-form-show-password-div {
  position: relative;
}

.settings-form-show-password {
  width: 30px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
}

.settings-message {
  font-size: 0.85rem;
  text-align: right;
  margin: 0;
}

.settings-message-success {
  color: var(--green) !important;
}

.settings-message-error {
  color: var(--red) !important;
}



.settings-save-btn {
  display: block;
  width: 21rem;
  height: 5rem;
  margin: 2rem 1rem 0 auto;
  border-radius: 50px;
  border: none;
  font-size: 2rem;
  font-weight: 700;
  background-color: var(--green);
  color: var(--white);
}

.settigns-block-btn {
  display: block;
  width: 100%;
  height: 5rem;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 50px;
  margin-bottom: 1.5rem;
  transition: all 0.125s ease;
}

.settigns-block-btn--green {
  color: var(--white);
  background-color: var(--green);
  border: none;
}

.settigns-block-btn--red {
  border: 1px solid var(--red);
  color: var(--red);
  background-color: transparent;
}

.settigns-block-btn--red:hover {
  background-color: var(--red);
  color: var(--white);
}



@media only screen and (max-width: 1480px) {
  .settings-container div {
    margin-left: 0;
    margin-right: 0;
  }

  #settings-form-container form:first-child {
    margin-right: var(--spacing-md);
  }
}


@media only screen and (max-width:980px) {
  #settings-form-container {
    display: flex;
    flex-direction: column;
  }

  #settings-form-container form {
    width: 100%;
  }

  #settings-form-container p {
    white-space: normal;
  }


  .settings-save-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  #settings-form-container h2 {
    font-size: 3.5rem;
  }

  #settings-form-container div {
    padding-top: 2rem;
  }

  @media only screen and (max-width:470px) {
    #settings-form-container {
      form {
        width: 40rem !important;
      }
    }
  }

  @media only screen and (max-width:420px) {
    #settings-form-container {
      form {
        width: 35rem !important;
      }
    }
  }

  @media only screen and (max-width:370px) {
    #settings-form-container {
      form {
        width: 30rem !important;
      }
    }
  }
}
.municipality-dropdown {
  position: absolute;
  margin-top: 1rem;
  z-index: var(--z-index-modal);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
  padding: 20px;
  width: 90%;
  width: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.municipality-top-text {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: 700;
}

.municipality-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.municipality-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.municipality-dropdown-show-more {
  align-self: flex-end;
  font-size: var(--font-size-xx-small);
  text-decoration: underline;
  cursor: pointer;
}



.municipalitys-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 560px) {
  .municipality-dropdown {
    width: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .municipality-dropdown {
    width: 250px;
    margin-left: -8rem;
  }
}

@media only screen and (max-width: 387px) {
  .municipality-dropdown {
    margin-left: 0rem;
    width: 300px;
  }
}



@media only screen and (max-width: 370px) {
  .municipality-dropdown {
    width: 300px;
    margin-left: -11rem;
  }

  .municipality-content-wrapper {
    display: flex;
    flex-direction: column;

  }
}
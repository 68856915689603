.login-page-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-light);
  border-bottom: 1px solid var(--gray-medium);
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
}

.login-page {
  width: 60rem;
  border-radius: 52px;
  padding: 8rem 12rem 6rem;
  margin: 8rem 0 12rem;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
}

.login-page h1 {
  font-size: var(--font-size-small);
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

#login-page-instructions {
  font-size: var(--font-size-xx-small);
  margin-bottom: 1rem;
  text-align: center;
}

#login-page-success {
  font-size: var(--font-size-xx-small);
  text-align: center;
  color: var(--green);
}

#login-page-error {
  font-size: var(--font-size-xx-small);
  text-align: center;
  color: var(--red);
}

.login-page form label {
  font-size: var(--font-size-xx-small);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.login-page form input {
  display: block;
  width: 100%;
  height: 5rem;
  font-size: var(--font-size-xx-small);
  padding: 0 2.5rem;
  border: 1px solid #c6cbcf;
  border-radius: 50px;
  margin-bottom: 0.5rem;
}

.login-page form input &:last-child {
  margin-bottom: 0;
}

.login-password-div {
  position: relative;
}

.login-form-show-password {
  width: 30px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
}

#login-page-forgot-password {
  width: 100%;
  margin: 1rem 0 2rem;

}

#login-page-forgot-password a {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-size: var(--font-size-xx-small);
  color: var(--text-black);
}

#login-page-buttons {
  margin-bottom: 2rem;
}

#login-page-buttons button {
  display: block;
  margin: 0 auto;
  width: 19rem;
  cursor: pointer;
  height: 5rem;
  font-size: var(--font-size-xx-small);
  border-radius: 50px;
  box-shadow: var(--shadow-light);

  &:first-child {
    font-weight: 700;
    border: none;
    color: var(--white);
    background-color: var(--green);
  }

  &:last-child {
    background-color: var(--white);
    border: 1px solid var(--green);
    margin-top: 1rem;
  }
}

.login-page p {
  font-size: var(--font-size-xx-small);
}

.login-page a {
  color: var(--green);
}

.login-page {
  font-size: var(--font-size-xx-small);
}

@media only screen and (max-width: 450px) {
  .login-page {
    width: 90%;
    border-radius: 28px;
    padding: 4rem 2rem 3rem;
    margin: 4rem 0 6rem;
  }
}
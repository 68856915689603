.dashboard-page {
  display: grid;
  grid-template-columns: 1fr 5fr;
  overflow-x: hidden;
}

.breadcrumb-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

#dashboard-breadcrumbs,
#dashboard-breadcrumbs>a {
  font-size: 1.6rem;
  line-height: 1;
  color: var(--text-black);
  font-family: var(--montserrat);
  text-decoration: none;
  padding-bottom: var(--spacing-md);
  margin: 0;
}

#dashboard-breadcrumbs>a {
  margin: 0.85rem;

  &:first-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1560px) {
  .dashboard-page {
    grid-template-columns: 1fr 8fr;
  }
}

@media only screen and (max-width: 1280px) {
  .dashboard-page {
    grid-template-columns: auto 5fr;
  }

  /* grid-template-columns: 28rem 1fr; */
}

@media only screen and (max-width: 1100px) {
  .dashboard-page {
    grid-template-columns: auto 1fr;
  }
}

@media only screen and (max-width: 680px) {
  .dashboard-page {
    display: block;
  }
}

.dashboard-content {
  min-height: 100vh;
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-md);
  grid-column: 2 / -1;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1820px) {
  .dashboard-content {
    padding-left: 1rem;
    padding-right: var(--spacing-sm);
  }
}

@media only screen and (max-width: 1280px) {
  .dashboard-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 980px) {
  .dashboard-content {
    padding: var(--spacing-md) var(--spacing-xs);
  }
}

@media only screen and (max-width: 680px) {
  .dashboard-content {
    margin-left: 0rem;
  }
}
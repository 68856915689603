.unit-type-card {
  text-decoration: none;
}

.unit-type-card:hover {
  transform: scale(1.05);
  transition: all 0.15s ease-in-out;
}

@media only screen and (max-width: 960px) {
  .unit-type-card {
    grid-column: 1;
    padding-bottom: 20px;
  }
}

.unit-grid-item {
  width: 100%;
  max-width: 380px;
  min-width: 100px;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto auto auto;
  margin: 15px auto;
}

.unit-type-card h2 {
  font-weight: 600;
  font-size: var(--font-size-xx-small);
  justify-self: start;
  margin-top: 15px;
  color: #000;
}

.unit-type-card p {
  font-size: var(--font-size-xx-small);
  justify-self: start;
  margin-top: 10px;
  color: black;
}
.available-spaces-links-section {
  width: 100%;
  padding: 2rem;
  border-bottom: 1px solid var(--text-light);
}

.available-spaces-nav {
  width: 100%;
  display: flex;
}

.available-spaces-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  gap: 0rem 2rem;
}

.available-spaces-group {
  flex: 0 1 calc(25% - 2rem);
  min-width: 250px;
  margin-bottom: 1rem;
}

.links-header {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.links-list {
  list-style: none;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
}

.links-item {
  margin-bottom: 0.5rem;
}

.links-anchor {
  color: inherit;
  text-decoration: none;
}

.links-anchor:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1280px) {
  .available-spaces-content {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

}


@media only screen and (max-width: 630px) {
  .available-spaces-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

  }

}
.image-not-found-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%;
  border-radius: 0;
  background-color: var(--blue-light);
  color: var(--text-black);
  cursor: pointer;
}

.image-not-found-small svg {
  width: 20rem;
  height: auto;
}

.image-not-found-small span {
  font-size: var(--font-size-xx-small);
}
.time-dropdown {
  position: absolute;
  margin-top: 1rem;
  z-index: var(--z-index-tooltip);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
  padding: 20px;
  width: 90%;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.time-dropdown p {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
}

.time-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.time-checkbox-wrapper {
  display: flex;
  flex-direction: column;
}

.time-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .time-dropdown {
    margin-left: -15rem;
  }
}

@media only screen and (max-width: 550px) {
  .time-dropdown {
    margin-left: -23rem;
  }
}

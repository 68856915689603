.home-video-section {
  padding: var(--spacing-lg) 5% 0;
  padding-bottom: 6rem;
  display: grid;
  justify-content: center;
}

.home-about-video-inner {
  max-width: 1400px;
  display: grid;
  align-items: center;
  grid-gap: 8rem;
  grid-template-columns: 1fr 1fr;
}

.video-card-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-card-container video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#home-about-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

#home-about-text h2 {
  font-size: var(--font-size-small);
  font-weight: 800;
  padding-bottom: 1rem;
  color: var(--green);
}

.home-about-text-inner p {
  font-size: var(--font-size-xx-small);
  /*   margin-bottom: 2rem; */
}

#home-about-text .active-link-button {
  width: 28rem;
  padding: 1rem 2rem 1rem;
  margin-top: 2rem;
  font-size: var(--font-size-x-small);
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  color: var(--text-black);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 50px;
  border: none;
  align-self: center;
}

@media only screen and (max-width: 980px) {
  .home-about-video-inner {
    max-width: 1400px;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  .home-video-section {
    padding-bottom: 2rem;
  }
}

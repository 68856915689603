.contact-form-section {
  padding: var(--spacing-lg) var(--spacing-sm);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.contact-form-subheading {
  font-size: 2rem;
  text-align: center;
  color: var(--text-black);
  margin-bottom: 4rem !important;
}

.contact-form-section form {
  max-width: 82.5rem;
  margin: 0 auto;

  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-form-name input,
.contact-form-email-number input,
.contact-form-message input {
  height: 4rem;
  padding: 0 2.5rem;
  border-radius: 50px;
  font-size: 1.6rem;
  border: 1px solid #ced4da;
  width: 100%;
}

.contact-form-email-number {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.contact-form-message textarea {
  height: 15rem;
  font-size: 1.6rem;
  border-radius: 23px;
  padding: 1.5rem 2.5rem;

  border: 1px solid #ced4da;
  width: 100%;
}

.contact-form-section p {
  font-size: var(--font-size-xx-small);
  line-height: 2.5rem;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.contact-form-section h1 {
  max-width: 85rem;
  margin: 0 auto;

  font-size: var(--font-size-medium) !important;
  color: var(--text-black) !important;

  font-weight: 700;

  text-align: center;
}

.contact-form-section h2 {
  max-width: 85rem;
  margin: 0 auto;
  font-weight: 600;
  margin-bottom: 2rem !important;
  text-align: center;
  font-size: var(--font-size-x-small) !important;
  color: var(--green) !important;
}

.contact-form-checkbox {
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 35px 30px;
  gap: 0px 10px;
  accent-color: var(--green);
}

.contact-form-checkbox p {
  align-self: stretch;
  padding-top: 0.5rem;
}

.contact-form-checkbox span {
  grid-column: span 2;
  font-size: 1.4rem !important;
}

.contact-form-name span,
.contact-form-email-number span,
.contact-form-message span,
.contact-form-checkbox span {
  font-size: 1.4rem;
  color: var(--red);
}

.contact-form-send-button-container {
  display: flex;
  justify-content: center;
}

.contact-form-send-button-container p {
  font-size: var(--font-size-x-small);
  font-weight: 500;
}

.contact-form-checkbox span {
  font-size: var(--font-size-xx-small);
}

.contact-form-checkbox a {
  color: var(--green);
}

@media only screen and (max-width: 580px) {
  .contact-form-email-number {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
  }
}

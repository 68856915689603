.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  font-size: 1.75rem;
  font-weight: 700;

  padding: 0 13px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  letter-spacing: 0.01em;
  border-radius: 16px;
  line-height: 1.4;
  min-width: 32px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.pagination-container button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: var(--font-size-xx-small);
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 1rem;
  cursor: pointer;
  color: var(--black) !important;
  border: 1px solid #c7c7c7;
}

.pagination-container button.active {
  color: var(--green) !important;
}

.pagination-arrow {
  margin-top: 5px;
  cursor: pointer;
  color: var(--black) !important;
}

.no-ads-card-link-wrapper {
  text-decoration: none;
}

.no-results-container {
  padding-bottom: 2rem;
  width: auto;
  /*   overflow: hidden; */
  margin-top: -9rem;
}

.no-results-container h2 {
  text-align: center;
  font-weight: bold;
}

.no-results-container h2 {
  font-size: var(--font-size-xx-small);
}

.no-results-container a {
  text-decoration: underline;
  color: var(--green);
}

.no-results-ad-section {
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-results-ad-section h2 {
  margin-top: 2rem;
  font-size: var(--font-size-xx-small);
  font-weight: bolder;
  margin-bottom: 2rem;
}

.no-ads-h2 {
  margin-top: 2rem;
  font-size: var(--font-size-small) !important;
  font-weight: bolder;
  margin-bottom: 2rem;
}

.no-ads-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 30%);
  gap: 1rem 1%;
  grid-auto-rows: 1fr;
  justify-content: center;
}



.no-search-result-card {
  min-width: 250px;
  max-width: 300px;

  font-size: 2rem;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: var(--shadow-light);
  height: 43rem;
  background-color: #FAFAFA;
  transition: all 250ms ease-in-out;
  color: black;
}

.no-card-outer-link {
  text-decoration: none !important;
  color: black;
}


.search-no-card {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  width: 100%;
  height: 20rem;
}

.search-no-card p {
  font-size: var(--font-size-xx-small);
  flex-grow: 1;
  margin: 0;
  text-align: center;

}

.search-no-card h3 {
  font-size: var(--font-size-x-small);

  font-weight: bold;
  color: #222;
  width: 100%;
}

.search-no-card-info div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.search-no-card-info div:last-child {
  text-align: right;
}

.no-card-image {
  width: 100%;
  height: 20rem;
  aspect-ratio: 16 / 9;
  background-color: var(--green);
  color: var(--text-black);
  border-radius: 18px 18px 0px 0px;
  display: grid;

  align-content: center;
  justify-content: center;

}

.no-card-image p {

  font-weight: 600;
  color: #FAFAFA;
  font-size: var(--font-size-x-small);
  text-align: center !important;
}

.no-card-image a {

  font-weight: 600;
  color: #FAFAFA;
  text-align: center;
}
.no-ads-errormsg, .no-ads-found{
  font-size: var(--font-size-x-small);
}
@media only screen and (max-width: 1500px) {
  .two-column .no-ads-container {
    width: 200%;
    display: grid;
    grid-template-columns: 20% 20%;
    grid-auto-rows: 1fr;
    justify-content: center;
  }

  .two-column .no-ads-container :nth-child(3) {
    display: none;
  }
}


@media only screen and (max-width: 1100px) {
  .two-column .no-ads-container {
    width: 360%;
    display: grid;
    grid-template-columns: 13% 13%;
    grid-template-rows: 1fr;
    gap: 2rem
  }
}

@media only screen and (max-width: 800px) {
  .single-column .no-ads-container {
    width: 200%;
    grid-template-columns: 20% 20%;
    grid-template-rows: 1fr 1fr;
  }

  .single-column .no-ads-container :nth-child(3) {
    grid-row: 2;
    grid-column: 1 / span 2;
    width: 50%;
    justify-self: center;
  }


  .two-column .no-ads-container {
    width: auto;
    grid-template-columns: 40%;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .no-ads-container .no-ads-generated-ads :nth-child(3) {
    grid-row: 3;
    grid-column: 1;
    width: 100%;
    justify-self: start;
  }
}

@media only screen and (max-width: 850px) {
  .single-column .no-ads-container {
    width: 300%;
    grid-template-columns: 18% 18%;
    grid-template-rows: 1fr 1fr;
  }

  .no-results-ad-section {
    width: 80vh;
  }
}

@media only screen and (max-width: 800px) {
  .single-column .no-ads-container {
    grid-template-columns: 15% 15%;
    grid-template-rows: 1fr 1fr;
  }
}


@media only screen and (max-width: 700px) {
  .single-column .no-ads-container {
    width: 100vh;
    grid-template-columns: 32% 32%;
    grid-template-rows: 1fr 1fr;
  }

  .no-ads-generated-ads :nth-child(3) {
    grid-row: 2;
    grid-column: 1 span 2;
    width: 50%;

  }

  .no-results-ad-section {
    width: 70vh;
  }
}

@media only screen and (max-width: 630px) {
  .no-results-ad-section {
    width: 50vh;
  }

  .single-column .no-ads-container {
    width: 80vh;
    grid-template-columns: 36% 36%;
    grid-template-rows: 1fr 1fr;
  }
}

@media only screen and (max-width: 530px) {
  .single-column .no-ads-container {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .single-column .no-ads-container :nth-child(3) {
    width: 100%;
  }
}

@media only screen and (max-width: 470px) {
  .no-results-ad-section {
    width: 40vh;
  }

  .single-column .no-ads-container {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .no-results-ad-section {
    width: fit-content;
    overflow: hidden;
    padding-bottom: 3.5rem;
    margin: 0;
  }

  .single-column .no-ads-container {
    width: 90%;
  }
}
.rent-out-space-renter-section {
  padding: var(--spacing-lg) var(--spacing-lg) 0 var(--spacing-lg);
  max-width: 100%;
}

.rent-out-space-renter-section p {
  text-align: left;
  font-size: var(--font-size-xx-small);
}

.rent-out-space-renter-section h2 {
  text-align: left;
  font-size: var(--font-size-medium);
}

.rent-out-space-renter-info {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-bottom: var(--spacing-lg);
  text-align: right;
}

.rent-out-space-inside-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* align-items: center; */
  justify-content: center;
}

.rent-out-space-renter-info img {
  width: 22.5rem;
  margin-left: 15rem;
  margin-bottom: 1.2rem;
}

.rent-out-space-renter-info div {
  max-width: 60rem;
  text-align: center;
}

.rent-out-space-renter-info h2 {
  max-width: 50.5rem;
}

/* Media queries */
@media only screen and (max-width: 1550px) {
  .rent-out-space-renter-section {
    padding: 0 var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
  }
}

@media only screen and (max-width: 980px) {
  .rent-out-space-renter-info {
    text-align: left;
  }

  .rent-out-space-renter-info img {
    display: none;
  }

  .rent-out-space-renter-info h2 {
    font-size: var(--font-size-mobile-small);
    max-width: 100%;
    margin-left: 0;
  }

  .rent-out-space-renter-section {
    padding: var(--spacing-lg) var(--spacing-lg) 0 var(--spacing-lg);
  }
}

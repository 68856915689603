.heading-section {
  display: grid;
  grid-template-rows: auto auto;
}

.heading-section h1 {
  font-size: var(--font-size-medium);
  padding-bottom: 1rem;
}

.heading-address {
  align-self: center;
  grid-column: 1 / span 1;
}

.ad-address {
  font-size: var(--font-size-xx-small);
  color: var(--text-black);
  text-decoration: underline;
}

.ad-address svg {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.6rem;
  margin-bottom: -0.3rem;
}

@media only screen and (max-width: 980px) {
  .heading-section h1 {
    font-size: var(--font-size-x-small);
    padding-bottom: 0rem;

  }
}
.info-table {
  width: 100%;
  border-collapse: collapse;

}

.info-table tbody {
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);

}

.info-row {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  margin-bottom: 2rem;
}

.info-item {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  display: flex;
  margin-bottom: 1rem;
}

.info-item-span {
  font-size: var(--font-size-xx-small);
}

.info-item >svg {
  margin-left: 0.5em;
}

.tooltip {
  padding: 0.8rem 1.6rem;
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  z-index: var(--z-index-tooltip);
  color: var(--green);
  background-color: var(--white);
  border-radius: 4px;
}

@media only screen and (max-width: 660px) {
  .info-row {
    grid-template-columns: 1fr;
  }

  .info-item {
    min-width: 110px;
  }

  .info-table tbody {
    display: grid;
    grid-template-columns: repeat(auto-fill, 30%);

  }
}

@media only screen and (max-width: 490px) {
  .info-table tbody {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);

  }
}
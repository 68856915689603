.collaborations-section {
  padding: 1%;
  padding-top: 1rem !important;
}

.collaborations-section h5 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  text-align: center;
  padding-bottom: var(--spacing-lg);
}

.collaborations-section > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.collaborations-section div a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 6%; /* Increased margin for more space */
  width: auto;
}

.collaborations-section div a img {
  width: 100%;
  height: auto;
}

.collaborations-section a:nth-child(n + 1):nth-child(-n + 4) {
  width: auto;
  margin-bottom: 2.5%;
}

.single-collaborator img {
  max-width: 100%;
  max-height: 10rem;
}

.single-collaborator.sm img {
  transform: scale(1.3);
}

.single-collaborator.md img {
  transform: scale(2);
}

.single-collaborator.lg img {
  transform: scale(2.5);
  overflow: none;
}

.single-collaborator.seven img {
  margin-top: 1rem;
}

@media only screen and (max-width: 1240px) {
  .collaborations-section {
    padding: var(--spacing-xl) var(--spacing-sm);
  }
}

@media only screen and (max-width: 980px) {
  .single-collaborator.sm img {
    transform: scale(1);
  }

  .single-collaborator.md img {
    transform: scale(1.3);
  }

  .single-collaborator.lg img {
    transform: scale(1.5);
  }

  .collaborations-section h2 {
    font-size: var(--font-size-mobile-small);
  }
}

@media only screen and (max-width: 800px) {
  .collaborations-section > div > a {
    margin: 1rem 9% 2rem !important;
    width: 25% !important;
  }
}

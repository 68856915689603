.how-it-works-pricing-wrapper {
  background-color: var(--gray-light);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

#how-it-works-title {
  font-size: var(--font-size-medium);

  margin-top: var(--spacing-md);
  text-align: center;
}

.how-it-works-pricing {
  display: flex;
  justify-content: center;
}

.how-it-works-pricing-card {
  position: relative;
  display: grid;
  justify-items: center;
  grid-template-rows: 3.5rem 75px 60px 80px;
  width: 53rem;
  height: auto;
  background-color: var(--gray-light);
  border-radius: 28px;
  margin: 1.85rem;
  box-shadow: 0px 10px 24px #00000029;
  transition: all 0.15s ease-in-out;
  text-align: center;
}

.how-it-works-pricing-card:hover {
  box-shadow: var(--shadow-medium);
  transform: scale(1.02);
}

.how-it-works-pricing-card span {
  width: 100%;
  background-color: var(--green);
  border-radius: 28px 28px 0 0;
  font-size: var(--font-size-xx-small);
  padding: 0.6rem 0;
  color: white;
  font-weight: bold;
}

.how-it-works-pricing-text p {
  flex-grow: 1;
  font-size: var(--font-size-xx-small);
  text-align: center;
}

.how-it-works-pricing-card h4 {
  font-size: var(--font-size-medium);
  font-weight: 700;

  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.how-it-works-pricing-card p,
.how-it-works-pricing-card li,
.how-it-works-pricing-card a {
  font-size: var(--font-size-xx-small);
  margin: 15px;
}

.how-it-works-pricing-card li {
  display: flex;
  align-items: center;
}

.how-it-works-pricing-card svg {
  flex-shrink: 0;
  width: 4.25rem;
  height: 4.25rem;
  margin-right: 0.5rem;
  float: right;
}

.how-it-works-pricing-card ul {
  list-style: none;
  margin: 0;
  padding: 10px;
  text-align: left;
}

.how-it-works-pricing-card a {
  text-decoration: none;
  color: inherit;
  width: 22rem;
  border: none;
  background-color: var(--white);
  padding: 1rem 2rem;
  border-radius: 50px;
  box-shadow: var(--shadow-light);
  transition: all 0.15s ease-in-out;
  margin-bottom: 2rem;
  width: 24.5rem;
  height: 5rem;
}

.how-it-works-pricing-card a:hover {
  transform: scale(1.05);
}

.how-it-works-pricing-mest .rounded-link-button {
  margin-top: -2px;
}

.how-it-works-rent-a-room {
  padding: var(--spacing-lg);
  max-width: 1600px;
  text-align: center;
}

.how-it-works-rent-a-room h3 {
  font-size: var(--font-size-medium);
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.how-it-works-rent-a-room p {
  font-size: var(--font-size-xx-small);
  padding-bottom: 2rem;
  margin: 0 auto;
}

#bas-span,
#mest-span {
  display: flex;
  justify-content: center;
}

@media (max-width: 980px) {
  .how-it-works-pricing {
    flex-direction: column;
    justify-content: center;
  }

  .how-it-works-pricing-card {
    width: 90%;
    margin: 15px auto;
    grid-template-rows: auto auto 1fr auto;
  }

  #how-it-works-title,
  .how-it-works-pricing-card h4 {
    font-size: var(--font-size-mobile-small);
  }

  .how-it-works-pricing-card a {
    font-size: var(--font-size-xx-small);
  }

  .how-it-works-pricing-bas {
    height: auto;
  }

  .how-it-works-pricing-bas h4 {
    margin-top: 2.5rem;
  }

  .how-it-works-pricing-bas ul {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .how-it-works-pricing-mest p {
    margin-left: 20px;
  }

  .how-it-works-pricing-mest .rounded-link-button {
    margin-bottom: 25px;
  }

  .how-it-works-rent-a-room h3 {
    font-size: var(--font-size-mobile-small);
  }
}

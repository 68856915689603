.area-dropdown {
  position: absolute;
  left: 35%;
  transform: translateX(-35%);
  margin-top: 1rem;
  z-index: var(--z-index-modal);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
  padding: 20px;
  width: 350px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.area-dropdown p {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.area-button-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.area-slider-min-max {
  display: flex;
}

.area-dropdown hr {
  margin-top: 1rem;
}

.area-dropdown select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: var(--font-size-xx-small);
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.area-slider-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.area-slider .area-slider-container {
  max-width: 100%;
}

.area-slider .area-slider__left-value,
.area-slider .area-slider__right-value {
  width: calc(50% - 2.5rem);
  min-height: 8.15rem;
  padding: 0.75rem 1.5rem 0;
  border: 1px solid var(--gray-dark);
  border-radius: 12px;
  color: #dee2e6;
  font-size: 12px;
}

.area-slider .area-slider__left-value p,
.area-slider .area-slider__right-value p {
  font-size: 1.5rem;
  color: var(--text-black);
}

.area-slider .area-slider__left-value input,
.area-slider .area-slider__right-value input {
  border: none;
  font-size: 1.8rem;
  width: 100%;
}

.slider__right-value span {
  position: relative;
  top: -25px;
  left: 43px;
  color: black;
  font-size: var(--font-size-xx-small);
}

@media only screen and (max-width: 600px) {
  .area-slider .area-slider {
    width: 400px;
  }

  .area-slider .thumb {
    width: 400px;
  }
}

@media only screen and (max-width: 500px) {
  .area-slider .slider {
    width: 300px;
  }

  .area-slider .thumb {
    width: 300px;
  }
}

@media only screen and (max-width: 375px) {
  .area-slider .slider__left-value,
  .area-slider .slider__right-value {
    width: calc(50% - 1rem);
  }
}

@media only screen and (max-width: 650px) {
  .area-dropdown {
    width: 540px;
  }
}

@media only screen and (max-width: 530px) {
  .area-dropdown {
    width: 450px;
  }

  .area-slider-plus {
    top: 109px;
    left: 337px;
  }
}

@media only screen and (max-width: 530px) {
  .area-dropdown {
    width: 450px;
  }
}

@media only screen and (max-width: 490px) {
  .area-dropdown {
    width: 400px;
  }

  .area-slider-plus {
    top: 109px;
    left: 295px;
  }

  .area-slider .area-slider__left-value,
  .area-slider .area-slider__right-value {
    width: calc(50% - 0.5rem);
  }
}

@media only screen and (max-width: 430px) {
  .area-dropdown {
    width: 340px;
  }
}

@media only screen and (max-width: 550px) {
  .area-dropdown {
    margin-left: 0.5rem;
  }
}

.support-container h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
}

.support-container h3 {
  font-size: var(--font-size-small);
  font-weight: 700;
  margin-bottom: 1rem;
}

.support-container p {
  font-size: var(--font-size-xx-small);
}


.contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

.contact-info svg {
  font-size: 2.5rem;
  margin-right: 1.5rem;
}


#supportFeedbackTextarea {
  width: 100vh;
}

.support-questions {
  margin-bottom: 15rem;
}

.support-links {
  margin-top: 2.8rem;
}

.support-questions a {
  padding: 1rem 2rem;
  margin-right: 2.4rem;
  font-size: 2.2rem;
  text-decoration: none;
  color: var(--text-black);
  border-radius: 50px;
  border: none;
  background-color: white;
  box-shadow: 0px 10px 24px #0000002e;
}

.support-questions a:hover {
  background-color: var(--green);
  color: var(--white);
}


.support-feedback label {
  font-size: 1.8rem;

  margin: 3rem 1rem 1rem;
}

.support-feedback textarea {
  font-size: 1.6rem;
  border-radius: 23px;
  max-width: 82.25rem;
  padding: 1.5rem 2.3rem;
}

.support-response p {
  font-size: 1.8rem;

  margin: 1.5rem 0.5rem;
}

.support-response-success {
  color: var(--green);
}

.support-response -error {
  color: var(--red);
}


.support-feedback button {
  width: 16.25rem;
  height: 5rem;
  font-size: 2.2rem;
  border-radius: 50px;
  border: 1px solid var(--green);
  background-color: white;
  box-shadow: 0px 10px 24px #0000002e;
  cursor: pointer;
}


@media only screen and (max-width: 980px) {
  .support-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .support-links a {
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    border: 1px solid #707070;
  }


  #supportFeedbackTextarea {
    width: 100%;
  }

  .support-feedback button {
    width: 100%;
    border-radius: 12px;
    padding: 2rem;
    height: auto;
    background-color: var(--green);
    color: white;
    border: none;
  }

}
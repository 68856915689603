.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carousel-card-link {
  display: block;
  width: 65%;
  color: var(--gray-black);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  text-align: center;
}

.carousel-card-link:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.mobile-grid-item {
  height: 25rem;
  width: 100%;
  object-fit: cover;
  border-radius: 26px;
}

.carousel-card h3 {
  font-size: var(--font-size-small);
  font-weight: 700;

  margin-top: 2rem;
}

.carousel-card p {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  color: var(--gray-dark);
}

@media only screen and (max-width: 750px) {
  .carousel-card-link {
    color: var(--gray-black);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }

  .mobile-grid-item {
    margin: 0 auto;
    height: 20rem;
    width: 25rem;
    object-fit: cover;
    border-radius: 26px;
  }
}
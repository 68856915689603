.coworking-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}
.coworking-inner {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 4rem;
}
.coworking-container {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  height: auto;
  place-items: center;
}
.intro-img {
  max-width: 40rem;
  /* margin-right: -4rem; */
  width: 100%;
  height: 30rem;
  float: right;
  object-fit: contain;
}
.intro-text {
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  white-space: wrap;
}

.intro-text h1 {
  font-size: var(--font-size-large);
  color: var(--green);
}

.intro-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 1.2rem;
}

.intro-text h3 {
  font-size: var(--font-size-small);
  margin-bottom: 1.2rem;
}

.intro-text h4 {
  font-size: var(--font-size-x-small);
  margin-bottom: 1.2rem;
}

.intro-text p {
  font-size: var(--font-size-xx-small);
}

.intro-text a {
  color: var(--green);
}

.intro-text .rounded-link-button {
  width: auto;
  padding: 3rem 4rem 3rem;
  margin-top: 2rem;
  font-size: var(--font-size-x-small);
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  box-shadow: var(--shadow-light);
  border-radius: 50px;
  border: none;
  align-self: flex-start;
}

.intro-img-container {
  width: auto;
  height: auto;
}

@media only screen and (min-width: 1300px) {
  .intro-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    height: auto;
  }
}

@media only screen and (max-width: 980px) {
  .intro-content {
    padding: 0;
  }
  .coworking-container {
    display: block;
  }
  .coworking-inner {
    padding: 0;
    margin: 0, 1rem, 0, 1rem;
  }
  .intro-text h2 {
    font-size: var(--font-size-mobile-small);
  }
}

@media only screen and (max-width: 863px) {
  .intro-img-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 650px) {
  .intro-img-container {
    display: none;
  }
  .intro-img {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .intro-text .rounded-link-button {
    align-self: center;
  }

  .intro-text p,
  .intro-text b {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .intro-text {
    margin-bottom: 4rem;
  }

  .intro-text h1,
  .intro-text h2,
  .intro-text h3,
  .intro-text h4 {
    margin-left: 1rem;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 420px) {
  .intro-text .rounded-link-button {
    width: 98%; /* Make the button less wide */
    align-self: center; /* Center the button */
    font-size: medium;
  }
}

@media only screen and (max-width: 380px) {
  .intro-text {
    margin-right: 0.4rem;
  }

  .intro-text .rounded-link-button {
    width: 90%; /* Make the button less wide */
    align-self: center; /* Center the button */
    font-size: medium;
  }
}

.navbar-component {
  position: fixed;
  top: 0;
  z-index: var(--z-index-navbar);
  display: flex;
  align-items: center;
  width: 100vw;
  height: var(--navbar-height);
  padding: 0 var(--spacing-lg);
  background-color: #fff;
}



.navbar-logo {
  width: 15rem;
  height: auto;
  align-self: center;
  margin-right: auto;
  cursor: pointer;
}

.navbar-component nav {
  margin-right: 3.4rem;
}

.navbar-component nav a {
  font-size: 1.8rem;
  text-decoration: none;
  color: var(--text-black);
  margin-left: 2.85rem;
  transition: color 0.15s ease;
  font-weight: 500;
}

.navbar-component nav a &:hover {
  color: var(--text-black);
}

@media only screen and (max-width: 1160px) {
  .navbar-component {
    padding: 0 var(--spacing-md);
  }

  .navbar-logo {
    width: 14rem;
    margin-bottom: 0.75rem;
  }

  .navbar-component nav {
    margin-right: 2rem;
  }

  .navbar-component nav a {
    margin-left: 1.92rem;
  }
}

/* Change to hamburger menu */
@media only screen and (max-width: 980px) {
  .navbar-component nav {
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .navbar-component {
    padding: 0 var(--spacing-lg);
  }
}



.navbar-dropdown {
  position: relative;
}

#navbar-dropdown-icon {
  width: 5rem;
  height: 5rem;
  border-radius: 100px;
  border: 3px solid var(--green);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

#navbar-dropdown-icon img {
  width: 6.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#navbar-dropdown-icon p {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--green);
  background-color: var(--white);
}

#navbar-dropdown-icon[loggedIn="false"] p {
  color: var(--green);
  background-color: var(--white);
}

#navbar-dropdown-menu {
  position: absolute;
  top: 6rem;
  right: -1rem;
  z-index: var(--z-index-popover);
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 1.5rem 0;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 14px;
}

#navbar-dropdown-menu a,
#navbar-dropdown-menu button {
  display: block;
  font-size: 2rem;
  color: var(--text-black);
  padding: 1rem 3rem;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

#navbar-dropdown-menu a,
#navbar-dropdown-menu b {
  font-size: var(--font-size-x-small) !important;
}

#navbar-dropdown-menu hr {
  border: none;
  border-top: 1px solid var(--text-black);
  margin: 1rem 0;
}


/* Change to hamburger menu */
@media only screen and (max-width: 980px) {
  .navbar-dropdown {
    display: none;
  }
}



.navbar-mobile-login {
  display: none;
  width: 5.5rem;
  height: 5.5rem;

}

/* Change to hamburger menu */
@media only screen and (max-width: 980px) {
  .navbar-mobile-login {
    display: block;
  }
}
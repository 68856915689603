.booking-panel {
  top: var(--spacing-lg);
  left: 50%;
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-sm);
  background-color: var(--white);
  border-radius: 28px;
}

.booking-panel h4 {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.booking-panel h3 {
  font-size: var(--font-size-x-small) !important;
  margin-bottom: 0.4rem !important;
}

.booking-panel p {
  font-size: var(--font-size-xx-small);
}

.booking-panel-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.booking-modal-error {
  font-size: var(--font-size-xx-small);
  text-align: center;
  margin: 0 0.5rem 1rem;
  font-weight: 400;
}

.booking-modal-error {
  color: var(--red);
  white-space: nowrap;
  font-weight: 400;
}

.booking-modal-message {
  margin: 0rem 0 1rem;
}

.booking-modal-message button {
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  color: var(--text-black);
  padding: 0.4rem 1.2rem;
  border: 1px solid var(--gray-dark);
  border-radius: 6px;
  background: none;
}

.booking-modal-message svg {
  font-size: var(--font-size-xx-small);
  color: var(--text-black);
  margin-top: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
}

.booking-modal-message textarea {
  width: 100%;
  font-size: 1.6rem;
  border-radius: 23px;
  padding: 1rem 1rem;
  margin-top: 1rem;
  height: 115px;
  border: 1px solid #c6cbcf;
}

.booking-panel form {
  .booking-modal-input-row {
    display: flex;
    justify-content: space-between;
  }
}

.booking-panel label {
  font-size: var(--font-size-xx-small);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.booking-panel input[type="text"],
input[type="number"] {
  display: block;
  width: 100%;
  height: 4rem;
  font-size: 1.6rem;
  padding: 0 2.5rem;
  border: 1px solid #c6cbcf;
  border-radius: 50px;
  margin-bottom: 1.5rem;
}

.booking-form-is-required-text {
  margin-bottom: 2rem;
}

.booking-modal-buttons {
  margin: 3rem 0 1.5rem;
}

.booking-modal-buttons button {
  display: block;
  margin: 0 auto;
  width: 19rem;
  height: 5rem;
  font-size: var(--font-size-xx-small);
  font-weight: 700;
  color: var(--white);
  background-color: var(--green);
  box-shadow: var(--shadow-light);
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.booking-modal-form {
  cursor: pointer;
}

.booking-modal-bottom-text {
  font-size: 1.6rem;
  text-align: center;
}

.checkbox-speciffic a {
  color: var(--green);
}

.booking-modal-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  height: 8rem;
  width: 8rem;
  background: none;
  border: none;
}

#booking-phone {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.invalid {
  border: 1px solid var(--red) !important;
}

@media only screen and (max-width: 740px) {
  .booking-panel {
    width: 95%;
  }

  .booking-panel textarea {
    height: 100px;
  }
}

@media only screen and (max-width: 620px) {
  .booking-panel {
    width: 95%;
  }

  .booking-panel textarea {
    height: 100px;
  }
}

@media only screen and (max-width: 430px) {
  .booking-panel h1 {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 390px) {
  .booking-panel {
    width: 95%;
  }

  .booking-modal-close-button {
    height: 5rem !important;
    width: 5em !important;
  }

  .booking-panel h1 {
    margin-top: 3rem;
  }

  .booking-panel form {
    .booking-modal-input-row,
    .booking-modal-input-row > div {
      display: block;
      width: 100%;
    }

    label {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }

    input[type="text"],
    input[type="number"] {
      display: block;
      width: 100%;
      height: 5rem;
      padding: 0 2.5rem;
      border: 1px solid #c6cbcf;
      border-radius: 50px;
      margin-bottom: 1.5rem;
    }

    .booking-form-is-required-text {
      margin-bottom: 2rem;
    }
  }
}

.rounded-link-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--green);
  border: medium none;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  font-size: var(--font-size-xx-small);
  text-decoration: none;
}
.rounded-link-button:hover {
    color: var(--white);
    background-color: var(--green);
    cursor: pointer;
}
.rounded-link-button.green {
  color: var(--white);
  background-color: var(--green);
}

.rounded-link-button.white {
  background-color: var(--white);
  border: 2px var(--green) solid;
  color: var(--green);
}

.rounded-link-button.whitest {
  background-color: var(--white);
  border: none;
  color: inherit;
  font-weight: 400;
  box-shadow: var(--shadow-light);
}

.rounded-link-button.red {
  background-color: var(--red);
  border: 2px var(--green) solid;
  color: var(--white);
  border: medium none;
}

.rounded-link-button.sm {
  width: 14rem;
  height: 4rem;
}

.rounded-link-button.md {
  width: 16.5rem;
  height: 4.5rem;
}

.rounded-link-button.lg {
  width: 24.5rem;
  height: 5rem;
}
.rounded-link-button.xl {
  width: 27.5rem;
  height: 5rem;
}

.subletting-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.subletting-inner {
  padding: var(--spacing-lg);
}

.subletting-container {
  min-height: 0;
}

.subletting-intro-text h1 {
  font-size: var(--font-size-large);
  color: var(--green);
  max-width: 1600px;
  margin: 0 auto;
}

.subletting-intro-text h3 {
  font-size: var(--font-size-x-small);
}

.subletting-intro-text h2 {
  font-size: var(--font-size-medium);
  text-align: left;
  margin-bottom: 0rem;
}

.subletting-intro-text p, b {
  margin: 0;
  font-size: var(--font-size-xx-small);
}

.subletting-intro-text a {
  color: var(--green);
}

.subletting-vision-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 0rem;
}

.subletting-vision-text p {
  font-size: var(--font-size-xx-small);
}

@media only screen and (max-width: 980px) {
  .about-us-intro-text h1 {
    font-size: var(--font-size-large);
  }

  .subletting-intro-text h2 {
    font-size: var(--font-size-mobile-small);
  }
}
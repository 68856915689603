.facilities-form-component {
  white-space: nowrap;
  margin-bottom: 1rem;
}

.facilities-form-component span {
  margin-left: 1rem;
}


.facilities-checkboxes-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 225px);
  margin-top: 4rem;
}

@media only screen and (max-width: 767px) {
  .facilities-checkboxes-container {
    grid-template-columns: repeat(auto-fill, 175px);
    grid-gap: 0.5rem 5rem;
  }
}